import React from "react";
import TaxRefund from "../../components/TaxRefund";

function TaxRefundPage(props) {
  return (
    <div className="fixed-height">
      <TaxRefund />
    </div>
  );
}

export default TaxRefundPage;
