import React, { useEffect } from "react";
import "./howitworks.css";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

function HowItWorks(props) {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="refund-desc page-margin">
      <h4>{t("how.title")}</h4>
      <p className="form-description">
        {t("how.embark")} <span className="hightlight-text">Veg</span>
        <span className="hightlight-text-blue">Tax</span> {t("how.aswe")}
      </p>
      <div className="how-it-works-posters">
        <div className="how-it-works-poster">
          <div className="how-it-works-icon">
            <img src="/documentIcon.png" />
          </div>
          <div className="poster-texts">
            <div className="poster-title-wrapper">
              <p className="number">1.</p>
              <h3 className="poster-title">{t("how.assess")}</h3>
            </div>

            <p className="poster-description">{t("how.dive")}</p>
            <button
              className="get-tax-refund-btn"
              style={{ width: "125px" }}
              onClick={() => navigate("/tax-refund")}
            >
              {t("how.letsgo")}
            </button>
          </div>
        </div>
        <div className="thick-arrow">
          <img src="/thickArrow.png" />
        </div>
        <div className="how-it-works-poster">
          <div className="how-it-works-icon">
            <img src="/uploadBigIcon.png" />
          </div>
          <div className="poster-texts">
            <div className="poster-title-wrapper">
              <p className="number">2.</p>
              <h3 className="poster-title">{t("how.subm")}</h3>
            </div>

            <p className="poster-description">{t("how.say")}</p>
          </div>
        </div>
        <div className="thick-arrow">
          <img src="/thickArrow.png" />
        </div>
        <div className="how-it-works-poster">
          <div className="how-it-works-icon">
            <img src="/paymentIcon.png" />
          </div>
          <div className="poster-texts">
            <div className="poster-title-wrapper">
              <p className="number">3.</p>
              <h3 className="poster-title">{t("how.pay")}</h3>
            </div>

            <p className="poster-description">{t("how.exp")}</p>
          </div>
        </div>
      </div>
      <div className="page-hero">
        <div className="homepage-hero-image">
          <img src="/whyChoseVegTax.png" alt="why chose vegtax" />
        </div>
        <div className="homepage-hero-slogan-block">
          <h1 className="homepage-hero-slogan">
            <span
              className="homepage-hero-slogan-1"
              style={{ color: "#000E2B" }}
            >
              {t("how.why")} VEG<span style={{ color: "#55C9EA" }}>TAX</span>?
            </span>
          </h1>
          <ul
            style={{
              listStyle: "initial",
              paddingLeft: "20px",
              marginTop: "12px",
            }}
          >
            <li className="list">
              <p className="hightlight-text">{t("how.speed")}</p>
              <p className="text-regular">{t("how.our")}</p>
            </li>
            <li className="list">
              <p className="hightlight-text">{t("how.data")}</p>
              <p className="text-regular">{t("how.trust")}</p>
            </li>
            <li className="list">
              <p className="hightlight-text">{t("how.expert")}</p>
              <p className="text-regular">{t("how.ourteam")}</p>
            </li>
          </ul>
        </div>
      </div>
      <div className="ready-to-taxrefund">
        <h3 className="big-title" style={{ marginBottom: "10px" }}>
          {t("how.ready")}
        </h3>
        <p
          className="ready-to-taxrefund-description"
          style={{ marginBottom: "10px" }}
        >
          {t("how.click")}&nbsp;
          <span style={{ fontWeight: "bold", fontFamily: "Gotham Medium" }}>
            VEG
          </span>
          <span style={{ color: "#55C9EA", fontFamily: "Gotham Medium" }}>
            TAX
          </span>
          .{t("how.guide")}
        </p>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <button
            className="get-tax-refund-btn"
            style={{ width: "125px" }}
            onClick={() => navigate("/tax-refund")}
          >
            {t("how.letsgo")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;
