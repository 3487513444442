import React, { useEffect } from "react";
import "./whoweare.css";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

function WhoWeAre(props) {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="who-we-are-block">
        <div className="homepage-hero" style={{ padding: "0" }}>
          <div className="homepage-hero-image">
            <img src="/tax-refund.png" alt="tax refund" />
          </div>
          <div
            className="homepage-hero-slogan-block"
            style={{ marginBottom: "20px" }}
          >
            <h1 className="homepage-hero-slogan">
              <span className="who-we-are-title">
                {t("homepage.whoweare.title")}
              </span>
            </h1>
            {i18n.language === "en" ? (
              <p className="homepage-hero-slogan-text">
                <span className="hightlight-text">VEG Consulting</span>
                {t("homepage.whoweare.recognized")}
                <span className="hightlight-text">
                  {t("homepage.whoweare.tax")}
                </span>{" "}
                {t("and")}
                <span className="hightlight-text">
                  {" "}
                  {t("homepage.whoweare.financial")}{" "}
                </span>
                {t("homepage.whoweare.consulting")}
                <span className="hightlight-text">VEG</span>
                <span className="hightlight-text-blue">TAX</span>.{" "}
                {t("homepage.whoweare.thisinnovative")}&nbsp;
                <span className="hightlight-text">
                  {t("homepage.whoweare.swissind")}{" "}
                </span>
                {t("homepage.whoweare.handle")}.
              </p>
            ) : (
              <p className="homepage-hero-slogan-text">
                <span className="hightlight-text">VEG Consulting</span>, un
                leader reconnu dans le domaine du conseil&nbsp;
                <span className="hightlight-text">fiscal</span> et{" "}
                <span className="hightlight-text">financier</span> dans le
                canton de Vaud, est fier d'annoncer le lancement de son tout{" "}
                <span className="hightlight-text">nouveau produit :</span>{" "}
                <span className="hightlight-text">VEG</span>{" "}
                <span className="hightlight-text-blue">TAX</span>. Ce site
                internet <span className="hightlight-text">innovant</span> est
                spécialement conçu pour{" "}
                <span className="hightlight-text">solutionner</span> la manière
                dont les particuliers suisses gèrent leurs{" "}
                <span className="hightlight-text">déclarations d'impôt</span>.
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="who-we-are-stamp-block">
        <div className="homepage-hero-slogan-block">
          <h1 className="homepage-hero-slogan">
            <span className="who-we-are-title">
              VEG<span style={{ color: "#55C9EA" }}>TAX</span>: {t("simpl")}
            </span>
          </h1>
          {i18n.language === "en" ? (
            <p className="homepage-hero-slogan-text">
              The <span className="hightlight-text">VEG</span>
              <span className="hightlight-text-blue">TAX</span>. website is an
              intuitive and secure online platform that allows users to&nbsp;
              <span className="hightlight-text">easily</span> and&nbsp;
              <span className="hightlight-text">quickly</span> submit their tax
              declarations. Leveraging the&nbsp;
              <span className="hightlight-text">expertise</span> and&nbsp;
              <span className="hightlight-text">reputation</span> of VEG
              Consulting, we provide you with a reliable and efficient tool to
              navigate the complex Swiss tax system.
            </p>
          ) : (
            <p className="homepage-hero-slogan-text">
              Le site <span className="hightlight-text">VEG</span>
              <span className="hightlight-text-blue">TAX.</span> est une
              plateforme en ligne{" "}
              <span className="hightlight-text">intuitive</span> et{" "}
              <span className="hightlight-text">sécurisée</span>, qui permet aux
              utilisateurs de soumettre{" "}
              <span className="hightlight-text">facilement</span> et{" "}
              <span className="hightlight-text">rapidement</span> leurs
              déclarations d'impôt. En s'appuyant sur l'expertise et la
              réputation de{" "}
              <span className="hightlight-text">VEG Consulting</span>, nous vous
              offrons un outil fiable et
              <span className="hightlight-text"> efficace</span> pour naviguer
              dans le complexe système fiscal suisse.
            </p>
          )}
        </div>
        <div className="veg-stamp">
          <img src="/vegStamp.png" alt="vegconsulting logo" />
        </div>
      </div>

      <div className="homepage-hero" style={{ padding: "0" }}>
        <div className="homepage-hero-image">
          <img src="/whyChoseVegTax.png" alt="why chose vegtax" />
        </div>
        <div className="homepage-hero-slogan-block">
          <h1 className="homepage-hero-slogan">
            <span
              className="homepage-hero-slogan-1"
              style={{ color: "#000E2B" }}
            >
              {t("how.why")}
              <span> VEG</span>
              <span style={{ color: "#55C9EA" }}>TAX</span>?
            </span>
          </h1>
          <ul
            style={{
              listStyle: "initial",
              paddingLeft: "20px",
              marginTop: "12px",
            }}
          >
            <li className="list">
              <p className="hightlight-text"> {t("how.speed")}</p>
              <p className="text-regular">{t("how.our")}</p>
            </li>
            <li className="list">
              <p className="hightlight-text"> {t("how.data")}</p>
              <p className="text-regular">{t("how.trust")}</p>
            </li>
            <li className="list">
              <p className="hightlight-text"> {t("how.expert")}</p>
              <p className="text-regular">{t("how.ourteam")}</p>
            </li>
          </ul>
        </div>
      </div>
      <div className="ready-to-taxrefund">
        <h3 className="big-title" style={{ marginBottom: "10px" }}>
          {t("how.ready")}
        </h3>
        <p
          className="ready-to-taxrefund-description"
          style={{ marginBottom: "10px" }}
        >
          {t("how.click")}&nbsp;
          <span style={{ fontWeight: "bold", fontFamily: "Gotham Medium" }}>
            VEG
          </span>
          <span style={{ color: "#55C9EA" }}>TAX</span>. {t("how.guide")}
        </p>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <button
            className="get-tax-refund-btn"
            style={{ width: "125px" }}
            onClick={() => navigate("/tax-refund")}
          >
            {t("how.letsgo")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default WhoWeAre;
