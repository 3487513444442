import React, { useState } from "react";
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useAppState } from "../../contexts/formContext";
import { useNavigate, useLocation } from "react-router";
import { useTranslation } from "react-i18next";

const PUBLIC_KEY =
  "pk_test_51OcOueELarn8wZoadBVREb9e8ZXO618qfwBxlz1EgHD8Sj6ZEtI9eINtiWkxfENrYk0p25oqQXkxjwEMtK0T1Vha00g1KttzNF";
const stripeTestPromise = loadStripe(PUBLIC_KEY);

const StripeWrapper = (props) => (
  <Elements stripe={stripeTestPromise} options={{ locale: "en" }}>
    <StripeContainer {...props} />
  </Elements>
);

function StripeContainer({ price, validPersonalInformation }) {
  const stripe = useStripe();
  const elements = useElements();
  const [enablePayButton, setEnablePayButton] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [checkOutError, setCheckOutError] = useState();
  const [state, setState] = useAppState();
  const [t] = useTranslation("global");

  const [serverError, setServerError] = useState(false);
  const [success, setSuccess] = useState(false);

  const [sendingRequest, setSendingRequest] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const url = "http://localhost:5000/api/stripe-payment";

  const onCardElementChange = (e) => {
    if (e.empty === false && e.complete === true) {
      setEnablePayButton(true);
    } else {
      setEnablePayButton(false);
    }
  };

  const onSuccessfullPayment = () => {
    setCheckOutError(null);
    submitForm();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const billingInformation = {
      name: state.personalInformation["name and lastname"],
      email: state.personalInformation.email,
      phone: state.personalInformation.mobile,
    };

    setProcessing(true);

    try {
      const res = await axios.post(url, {
        data: { amount: price * 100 },
        headers: {
          "Content-Type": "application/json",
        },
      });

      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: billingInformation,
      });

      if (paymentMethodReq.error) {
        setCheckOutError(paymentMethodReq.error.message);
        setProcessing(false);
        return;
      }

      const { error } = await stripe.confirmCardPayment(res.data.clientSecret, {
        payment_method: paymentMethodReq.paymentMethod.id,
        return_url: "http://localhost:3000/success",
      });

      if (error) {
        setCheckOutError(error.message);
        setProcessing(false);
        return;
      }

      onSuccessfullPayment();
    } catch (e) {
      setCheckOutError(e.message);
      setProcessing(false);
    }
  };

  const submitForm = () => {
    setSendingRequest(true);
    axios
      .post(
        "http://localhost:5000/api/send-form",
        {
          test: "test",
        },
        { withCredentials: true }
      )
      .then((res) => {
        setServerError(false);
        setSendingRequest(false);
        navigate("/success", { state: { pathname: location.pathname } });
        //setSuccess(true);
      })
      .catch((error) => {
        setSuccess(false);

        setServerError(error);
        setSendingRequest(false);
      });
  };

  const cardElementOptions = { hidePostalCode: true };

  return (
    <>
      <div style={{ marginTop: "30px" }}>
        <div
          style={{
            border: "2px solid black",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <CardElement
            options={cardElementOptions}
            onChange={onCardElementChange}
          />
        </div>
        {checkOutError && <div className="payment-error">{checkOutError}</div>}

        <div style={{ marginTop: "30px" }}>
          <button
            className={
              !enablePayButton
                ? "next-step-button payment-button-disabled"
                : "next-step-button"
            }
            onClick={handleSubmit}
            disabled={!enablePayButton}
          >
            {t("finish")}
          </button>
        </div>
      </div>
      {(processing || sendingRequest) && <div className="page-overlay"></div>}
    </>
  );
}

export default StripeWrapper;
