import React, { useEffect } from "react";

import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

function TermsOfUse(props) {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page-margin">
      <h4 className="page-title">{t("terms")}</h4>
      <div className="eligibility-descriptions ">
        <p className="eligibility-description">
          Our <span className="hightlight-text">product</span> is an innovative
          web solution designed to&nbsp;
          <span className="hightlight-text">simplify</span> the online tax
          declaration process. Our goal is to make this process as&nbsp;
          <span className="hightlight-text">efficient</span> and&nbsp;
          <span className="hightlight-text">affordable </span> as possible while
          maintaining a&nbsp;
          <span className="hightlight-text">high quality of service</span>. Here
          are the key aspects of our offering:
        </p>
        <p className="eligibility-description">
          <span className="hightlight-text-blue">
            Accessibility and Simplicity:
          </span>
          &nbsp; Our platform is designed to be easily&nbsp;
          <span className="hightlight-text">accessible</span> and user-friendly
          for a <span className="hightlight-text">wide range</span> of clients.
          It guides the user through the tax declaration process&nbsp;
          <span className="hightlight-text">intuitively</span>, simplifying
          complicated steps.
        </p>
        <p className="eligibility-description">
          <span className="hightlight-text-blue">
            Transparent and Affordable Pricing:
          </span>
          &nbsp; We are committed to providing this service at a maximum rate
          of&nbsp;
          <span className="hightlight-text">CHF 250</span>. This pricing has
          been established to cater to clients with
          <span className="hightlight-text"> standard tax profiles</span>,
          without major complexities. The pricing model aims to ensure that our
          services remain<span className="hightlight-text"> accessible </span>
          and <span className="hightlight-text">cost-effective </span>
          for the majority of users.
        </p>
        <p className="eligibility-description">
          <span className="hightlight-text-blue">
            Targeting Standard Profiles:
          </span>
          Our service is ideal for clients with relatively&nbsp;
          <span className="hightlight-text">simple</span> and&nbsp;
          <span className="hightlight-text">
            straightforward tax situations
          </span>
          . This includes individuals with regular incomes, few or no complex
          investments, and standard deductions. By focusing on these profiles,
          we can provide a <span className="hightlight-text">faster</span>
          &nbsp;and&nbsp;
          <span className="hightlight-text">more efficient</span>&nbsp;service.
        </p>
        <p className="eligibility-description">
          <span className="hightlight-text-blue">
            Solution for Complex Profiles:
          </span>
          For clients with&nbsp;
          <span className="hightlight-text">more complex needs</span> that go
          beyond our standard pricing framework, we provide guidance to
          our&nbsp;
          <span className="hightlight-text">parent company</span>, specialized
          in more intricate tax situations.
        </p>
        <p className="eligibility-description">
          <span className="hightlight-text-blue">In summary</span>, our web
          product is a <span className="hightlight-text">practical</span>,&nbsp;
          <span className="hightlight-text">affordable</span>, and&nbsp;
          <span className="hightlight-text">quality</span> solution for online
          tax declarations, primarily aimed at clients with&nbsp;
          <span className="hightlight-text">standard tax profiles</span>. Our
          commitment to transparent pricing and service quality sets us apart,
          while also providing&nbsp;
          <span className="hightlight-text">tailored solutions</span> for
          more&nbsp;
          <span className="hightlight-text">complex cases</span>.
        </p>
      </div>
      <div className="ready-to-taxrefund">
        <h3 className="big-title" style={{ marginBottom: "10px" }}>
          {t("how.ready")}
        </h3>
        <p
          className="ready-to-taxrefund-description"
          style={{ marginBottom: "10px" }}
        >
          {t("how.click")}&nbsp;
          <span style={{ fontWeight: "bold", fontFamily: "Gotham Medium" }}>
            VEG
          </span>
          <span style={{ color: "#55C9EA" }}>TAX</span>. {t("how.guide")}
        </p>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <button
            className="get-tax-refund-btn"
            style={{ width: "125px" }}
            onClick={() => navigate("/tax-refund")}
          >
            {t("how.letsgo")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default TermsOfUse;
