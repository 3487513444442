import React, { useEffect } from "react";
import "./step2.css";
import { useAppState } from "../../contexts/formContext";
import ActivityInputs from "../activityInputs";
import AlimonyInputs from "../alimonyInputs";
import ValidationError from "../validationError";
import { useTranslation } from "react-i18next";

function Step2({ step2ValidationErrors, invalidateStep2Errors }) {
  const [state, setState] = useAppState();
  const [t, i18n] = useTranslation("global");

  const handleSetCheckBoxValue = (key, value) => {
    invalidateStep2Errors(key);
    setState((state) => {
      return { ...state, [key]: value };
    });
  };

  const handleSelectSalariedActivities = (key, value) => {
    invalidateStep2Errors("type de Activité");
    setState((state) => {
      return {
        ...state,
        "type de Activité": key,
      };
    });
  };

  const handleSelecPartner2tSalariedActivities = (key, value) => {
    invalidateStep2Errors("type de Activité Contribuable2");
    setState((state) => {
      return {
        ...state,
        "type de Activité Contribuable2": key,
      };
    });
  };

  const handleSelectFamilyAllowances = (key) => {
    setState((state) => {
      return {
        ...state,
        "Allocations familiales type": { type: key },
      };
    });
  };

  const handleSelectPartner2FamilyAllowances = (key) => {
    invalidateStep2Errors("Allocations familiales type Contribuable2");
    setState((state) => {
      return {
        ...state,
        "Allocations familiales type Contribuable2": { type: key },
      };
    });
  };

  const handleSelectAnnuitiesAndPension = (key) => {
    setState((state) => {
      const match = state?.["Rentes et pensions type"]?.find(
        (el) => el.value === key
      );
      if (match) {
        if (state?.["Rentes et pensions type"]?.length === 1) {
          const { ["Rentes et pensions type"]: rents, ...rest } = state;
          return { ...rest };
        } else {
          const newRents = state?.["Rentes et pensions type"].filter(
            (element) => {
              return element.value !== key;
            }
          );
          return { ...state, "Rentes et pensions type": newRents };
        }
      } else {
        if (state?.["Rentes et pensions type"]?.length > 0) {
          return {
            ...state,
            "Rentes et pensions type": [
              ...state["Rentes et pensions type"],
              { value: key },
            ],
          };
        } else {
          return { ...state, "Rentes et pensions type": [{ value: key }] };
        }
      }
    });
  };

  const handleSelectPartner2AnnuitiesAndPension = (key) => {
    setState((state) => {
      const match = state?.["Rentes et pensions type Contribuable2"]?.find(
        (el) => el.value === key
      );
      if (match) {
        if (state?.["Rentes et pensions type Contribuable2"]?.length === 1) {
          const { ["Rentes et pensions type Contribuable2"]: rents, ...rest } =
            state;
          return { ...rest };
        } else {
          const newRents = state?.[
            "Rentes et pensions type Contribuable2"
          ].filter((element) => {
            return element.value !== key;
          });
          return {
            ...state,
            "Rentes et pensions type Contribuable2": newRents,
          };
        }
      } else {
        if (state?.["Rentes et pensions type Contribuable2"]?.length > 0) {
          return {
            ...state,
            "Rentes et pensions type Contribuable2": [
              ...state["Rentes et pensions type Contribuable2"],
              { value: key },
            ],
          };
        } else {
          return {
            ...state,
            "Rentes et pensions type Contribuable2": [{ value: key }],
          };
        }
      }
    });
  };

  const handleSelectIncomeSource = (value) => {
    setState((state) => {
      if (
        state?.["Sélections des revenus"] &&
        state?.["Sélections des revenus"][value]
      ) {
        if (value === "Activites salariale") {
          const { [value]: type, ...rest } = state["Sélections des revenus"];
          invalidateStep2Errors("type de Activité");
          invalidateStep2Errors("activites");
          return {
            ...state,
            ["type de Activité"]: null,
            activites: null,
            "Sélections des revenus": { ...rest },
          };
        } else if (value === "Allocations familiales") {
          const { [value]: type, ...rest } = state["Sélections des revenus"];
          invalidateStep2Errors("Allocations familiales type");
          return {
            ...state,
            ["Allocations familiales type"]: null,

            "Sélections des revenus": { ...rest },
          };
        } else if (value === "Pensions alimentaires") {
          const { [value]: type, ...rest } = state["Sélections des revenus"];
          invalidateStep2Errors("Sélections des revenus Pensions alimentaires");
          return {
            ...state,
            "Sélections des revenus Pensions alimentaires": null,

            "Sélections des revenus": { ...rest },
          };
        } else if (value === "Rentes et pensions") {
          invalidateStep2Errors("Rentes et pensions type");
          const { [value]: type, ...rest } = state["Sélections des revenus"];
          return {
            ...state,
            ["Rentes et pensions type"]: null,

            "Sélections des revenus": { ...rest },
          };
        } else {
          const { [value]: type, ...rest } = state["Sélections des revenus"];
          return { ...state, "Sélections des revenus": { ...rest } };
        }
      } else {
        if (value === "Activites salariale") {
          invalidateStep2Errors("Sélections des revenus");
          return {
            ...state,
            activites: [{ id: 1 }],
            "Sélections des revenus": {
              ...state["Sélections des revenus"],
              [value]: { type: value },
            },
          };
        } else {
          invalidateStep2Errors("Sélections des revenus");
          return {
            ...state,
            "Sélections des revenus": {
              ...state["Sélections des revenus"],
              [value]: { type: value },
            },
          };
        }
      }
    });
  };

  const handleSelectPartner2IncomeSource = (value) => {
    setState((state) => {
      if (
        state?.["Sélections des revenus Contribuable2"] &&
        state?.["Sélections des revenus Contribuable2"][value]
      ) {
        if (value === "Activites salariale") {
          invalidateStep2Errors("type de Activité Contribuable2");
          invalidateStep2Errors("activites Contribuable2");
          const { [value]: type, ...rest } =
            state["Sélections des revenus Contribuable2"];

          return {
            ...state,
            ["type de Activité Contribuable2"]: null,
            "activites Contribuable2": null,
            "Sélections des revenus Contribuable2": { ...rest },
          };
        } else if (value === "Allocations familiales") {
          invalidateStep2Errors("Allocations familiales type Contribuable2");
          const { [value]: type, ...rest } =
            state["Sélections des revenus Contribuable2"];
          return {
            ...state,
            ["Allocations familiales type Contribuable2"]: null,

            "Sélections des revenus Contribuable2": { ...rest },
          };
        } else if (value === "Pensions alimentaires") {
          invalidateStep2Errors(
            "Sélections des revenus Pensions alimentaires Contribuable2"
          );
          const { [value]: type, ...rest } =
            state["Sélections des revenus Contribuable2"];
          return {
            ...state,
            "Sélections des revenus Pensions alimentaires Contribuable2": null,

            "Sélections des revenus Contribuable2": { ...rest },
          };
        } else if (value === "Rentes et pensions") {
          invalidateStep2Errors("Rentes et pensions type Contribuable2");
          const { [value]: type, ...rest } =
            state["Sélections des revenus Contribuable2"];
          return {
            ...state,
            ["Rentes et pensions type Contribuable2"]: null,

            "Sélections des revenus Contribuable2": { ...rest },
          };
        } else {
          const { [value]: type, ...rest } =
            state["Sélections des revenus Contribuable2"];
          return {
            ...state,
            "Sélections des revenus Contribuable2": { ...rest },
          };
        }
      } else {
        if (value === "Activites salariale") {
          invalidateStep2Errors("Sélections des revenus Contribuable2");
          return {
            ...state,
            "activites Contribuable2": [{ id: 1 }],
            "Sélections des revenus Contribuable2": {
              ...state["Sélections des revenus Contribuable2"],
              [value]: { type: value },
            },
          };
        } else {
          invalidateStep2Errors("Sélections des revenus Contribuable2");
          return {
            ...state,
            "Sélections des revenus Contribuable2": {
              ...state["Sélections des revenus Contribuable2"],
              [value]: { type: value },
            },
          };
        }
      }
    });
  };

  const handleSetMultipleEmployers = (key, value) => {
    setState((state) => {
      if (key === "no") {
        if (state?.activites?.length === 1) {
          return state;
        } else {
          return {
            ...state,
            "Plusieurs employeurs": null,
            activites: [{ id: 1 }],
          };
        }
      } else if (key === "2") {
        if (state?.activites?.length === 2) {
          return state;
        } else {
          return {
            ...state,
            "Plusieurs employeurs": null,
            activites: [{ id: 1 }, { id: 2 }],
          };
        }
      } else if (key === "3") {
        if (state?.activites?.length === 3) {
          return state;
        } else {
          return {
            ...state,
            "Plusieurs employeurs": null,
            activites: [{ id: 1 }, { id: 2 }, { id: 3 }],
          };
        }
      } else if (key === "plus") {
        if (state?.["Plusieurs employeurs"]) {
          return state;
        } else {
          const { activites, ...rest } = state;
          return {
            ...rest,
            "Plusieurs employeurs": "plus",
          };
        }
      }
    });
  };

  const handleSetPartner2MultipleEmployers = (key, value) => {
    setState((state) => {
      if (key === "no") {
        if (state?.["activites Contribuable2"]?.length === 1) {
          return state;
        } else {
          return {
            ...state,
            "Plusieurs employeurs Contribuable2": null,
            "activites Contribuable2": [{ id: 1 }],
          };
        }
      } else if (key === "2") {
        if (state?.["activites Contribuable2"]?.length === 2) {
          return state;
        } else {
          return {
            ...state,
            "Plusieurs employeurs Contribuable2": null,
            "activites Contribuable2": [{ id: 1 }, { id: 2 }],
          };
        }
      } else if (key === "3") {
        if (state?.["activites Contribuable2"]?.length === 3) {
          return state;
        } else {
          return {
            ...state,
            "Plusieurs employeurs Contribuable2": null,
            "activites Contribuable2": [{ id: 1 }, { id: 2 }, { id: 3 }],
          };
        }
      } else if (key === "plus") {
        if (state?.["Plusieurs employeurs Contribuable2"]) {
          return state;
        } else {
          const { ["activites Contribuable2"]: act, ...rest } = state;
          return {
            ...rest,
            "Plusieurs employeurs Contribuable2": "plus",
          };
        }
      }
    });
  };

  const onActivityChange = (e, key, id) => {
    setState((state) => {
      const newActivities = state.activites.map((act) => {
        if (act.id === id) {
          return { ...act, [key]: e.target.value.trimStart() };
        } else {
          return act;
        }
      });
      return {
        ...state,
        activites: newActivities,
      };
    });
  };

  const onContribuable2ActivityChange = (e, key, id) => {
    setState((state) => {
      const newActivities = state["activites Contribuable2"].map((act) => {
        if (act.id === id) {
          return { ...act, [key]: e.target.value.trimStart() };
        } else {
          return act;
        }
      });
      return {
        ...state,
        "activites Contribuable2": newActivities,
      };
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let alRes = false;
    let actRes = false;
    let al2Res = false;
    let act2res = false;
    const requiredFields = ["Nom/Prenom", "Adresse", "Domicile", "Montant"];

    requiredFields.forEach((field) => {
      if (state?.["Sélections des revenus Pensions alimentaires"]?.[field]) {
        alRes = true;
      } else {
        alRes = false;
      }
    });
    if (alRes === true) {
      invalidateStep2Errors("Sélections des revenus Pensions alimentaires");
    }
    if (state.activites) {
      state.activites.forEach((el) => {
        if (el.name && el.taux) {
          actRes = true;
        } else {
          actRes = false;
        }
      });
      if (actRes === true) {
        invalidateStep2Errors("activites");
      }
    }
    if (
      state["Situation personnelle"]?.frenchValue === "Marié" &&
      state?.["activites Contribuable2"]
    ) {
      state["activites Contribuable2"].forEach((el) => {
        if (el.name && el.taux) {
          act2res = true;
        } else {
          act2res = false;
        }
      });
      if (act2res === true) {
        invalidateStep2Errors("activites Contribuable2");
      }
    }

    if (
      state["Situation personnelle"]?.frenchValue === "Marié" &&
      state?.["Sélections des revenus Pensions alimentaires Contribuable2"]
    ) {
      requiredFields.forEach((field) => {
        if (
          state?.[
            "Sélections des revenus Pensions alimentaires Contribuable2"
          ]?.[field]
        ) {
          al2Res = true;
        } else {
          al2Res = false;
        }
      });
      if (al2Res === true) {
        invalidateStep2Errors(
          "Sélections des revenus Pensions alimentaires Contribuable2"
        );
      }
    }
  }, [state]);

  const onAlimonyChange = (e, key) => {
    setState((state) => {
      return {
        ...state,
        "Sélections des revenus Pensions alimentaires": {
          ...state?.["Sélections des revenus Pensions alimentaires"],
          [key]: e.target.value.trimStart(),
        },
      };
    });
  };

  const onAlimonyChangePartner2 = (e, key) => {
    setState((state) => {
      return {
        ...state,
        "Sélections des revenus Pensions alimentaires Contribuable2": {
          ...state?.[
            "Sélections des revenus Pensions alimentaires Contribuable2"
          ],
          [key]: e.target.value,
        },
      };
    });
  };

  const onDeleteActivity = (id) => {
    setState((state) => {
      const newActivities = state.incomeSource.activities.filter((act) => {
        return act.id !== id;
      });
      if (newActivities.length === 0) {
        const { activities, ...rest } = state.incomeSource;
        return { ...state, incomeSource: { ...rest } };
      } else {
        return {
          ...state,
          incomeSource: { ...state.incomeSource, activities: newActivities },
        };
      }
    });
  };

  return (
    <div>
      <div className="refund-desc">
        <h4>{t("trefundstep2.title")}</h4>
        <p className="form-description" style={{ fontStyle: "italic" }}>
          {t("trefundstep2.text")}
        </p>
      </div>
      <div className="checkboxes-block">
        <h4 className="checkboxes-title">{t("trefundstep2.fields.canton")}</h4>

        <div
          className="single-parent-hausehold-boxes"
          style={{ position: "relative" }}
        >
          <ValidationError
            error={step2ValidationErrors["canton de résidence"]}
            topPosition="-15px"
            message="Required"
          />
          <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
            <div
              className="no-checkbox"
              onClick={() =>
                handleSetCheckBoxValue("canton de résidence", "VD")
              }
            >
              {state?.["canton de résidence"] === "VD" && (
                <div className="no-checkbox-mark">
                  <img
                    src="/checkMarkIcon.png"
                    alt="checkmark"
                    className="check-mark-icon"
                  />
                </div>
              )}
            </div>
            <p className="no">VD</p>
          </div>
          <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
            <div
              className="no-checkbox"
              onClick={() =>
                handleSetCheckBoxValue("canton de résidence", "VS")
              }
            >
              {state?.["canton de résidence"] === "VS" && (
                <div className="no-checkbox-mark">
                  <img
                    src="/checkMarkIcon.png"
                    alt="checkmark"
                    className="check-mark-icon"
                  />
                </div>
              )}
            </div>
            <p className="no">VS</p>
          </div>
          <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
            <div
              className="no-checkbox"
              onClick={() =>
                handleSetCheckBoxValue("canton de résidence", "GE")
              }
            >
              {state?.["canton de résidence"] === "GE" && (
                <div className="no-checkbox-mark">
                  <img
                    src="/checkMarkIcon.png"
                    alt="checkmark"
                    className="check-mark-icon"
                  />
                </div>
              )}
            </div>
            <p className="no">GE</p>
          </div>
          <div className="no-checkbox-block">
            <div
              className="no-checkbox"
              onClick={() =>
                handleSetCheckBoxValue("canton de résidence", "FR")
              }
            >
              {state?.["canton de résidence"] === "FR" && (
                <div className="no-checkbox-mark">
                  <img
                    src="/checkMarkIcon.png"
                    alt="checkmark"
                    className="check-mark-icon"
                  />
                </div>
              )}
            </div>
            <p className="no">FR</p>
          </div>
        </div>
      </div>
      <div className="checkboxes-block">
        <h4 className="checkboxes-title">
          {t("trefundstep2.fields.incomeselection")}
        </h4>
        {state["Situation personnelle"]?.frenchValue === "Marié" && (
          <p className="partner-label">{t("part1")}</p>
        )}

        <div
          className="single-parent-hausehold-boxes"
          style={{ position: "relative" }}
        >
          <ValidationError
            error={step2ValidationErrors["Sélections des revenus"]}
            topPosition="-15px"
            message="Required"
          />
          <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
            <div
              className="no-checkbox"
              onClick={() => handleSelectIncomeSource("Activites salariale")}
            >
              {state?.["Sélections des revenus"]?.["Activites salariale"] && (
                <div className="no-checkbox-mark">
                  <img
                    src="/checkMarkIcon.png"
                    alt="checkmark"
                    className="check-mark-icon"
                  />
                </div>
              )}
            </div>
            <p className="no">{t("trefundstep2.fields.salact")}</p>
          </div>

          {state?.["Sélections des revenus"]?.["Activites salariale"] && (
            <div
              className="single-parent-hausehold-boxes horisontal"
              style={{ position: "relative" }}
            >
              <ValidationError
                error={step2ValidationErrors["type de Activité"]}
                topPosition={"-13px"}
                message="Required"
              />
              <div
                className="no-checkbox-block"
                style={{ marginBottom: "10px", marginRight: "20px" }}
              >
                <div
                  className="no-checkbox"
                  onClick={() =>
                    handleSelectSalariedActivities("Principale", true)
                  }
                >
                  {state?.["type de Activité"] === "Principale" && (
                    <div className="no-checkbox-mark">
                      <img
                        src="/checkMarkIcon.png"
                        alt="checkmark"
                        className="check-mark-icon"
                      />
                    </div>
                  )}
                </div>
                <p className="no">{t("trefundstep2.fields.main")}</p>
              </div>
              <div
                className="no-checkbox-block"
                style={{ marginBottom: "10px" }}
              >
                <div
                  className="no-checkbox"
                  onClick={() =>
                    handleSelectSalariedActivities("Accessoires", true)
                  }
                >
                  {state?.["type de Activité"] === "Accessoires" && (
                    <div className="no-checkbox-mark">
                      <img
                        src="/checkMarkIcon.png"
                        alt="checkmark"
                        className="check-mark-icon"
                      />
                    </div>
                  )}
                </div>
                <p className="no">{t("trefundstep2.fields.accessory")}</p>
              </div>
            </div>
          )}
          {state?.["Sélections des revenus"]?.["Activites salariale"] && (
            <div style={{ marginBottom: "10px" }}>
              <h6 className="input-title" style={{ marginTop: "0" }}>
                {t("trefundstep2.fields.mulemp")}
              </h6>
              <div className="single-parent-hausehold-boxes horisontal">
                <div
                  className="no-checkbox-block"
                  style={{ marginBottom: "10px", marginRight: "20px" }}
                >
                  <div
                    className="no-checkbox"
                    onClick={() => handleSetMultipleEmployers("no")}
                  >
                    {state?.activites?.length === 1 && (
                      <div className="no-checkbox-mark">
                        <img
                          src="/checkMarkIcon.png"
                          alt="checkmark"
                          className="check-mark-icon"
                        />
                      </div>
                    )}
                  </div>
                  <p className="no">{t("no")}</p>
                </div>
                <div
                  className="no-checkbox-block"
                  style={{ marginBottom: "10px", marginRight: "20px" }}
                >
                  <div
                    className="no-checkbox"
                    onClick={() => handleSetMultipleEmployers("2")}
                  >
                    {state?.activites?.length === 2 && (
                      <div className="no-checkbox-mark">
                        <img
                          src="/checkMarkIcon.png"
                          alt="checkmark"
                          className="check-mark-icon"
                        />
                      </div>
                    )}
                  </div>
                  <p className="no">2</p>
                </div>
                <div
                  className="no-checkbox-block"
                  style={{ marginBottom: "10px", marginRight: "20px" }}
                >
                  <div
                    className="no-checkbox"
                    onClick={() => handleSetMultipleEmployers("3")}
                  >
                    {state?.activites?.length === 3 && (
                      <div className="no-checkbox-mark">
                        <img
                          src="/checkMarkIcon.png"
                          alt="checkmark"
                          className="check-mark-icon"
                        />
                      </div>
                    )}
                  </div>
                  <p className="no">3</p>
                </div>
                <div
                  className="no-checkbox-block"
                  style={{ marginBottom: "10px" }}
                >
                  <div
                    className="no-checkbox"
                    onClick={() => handleSetMultipleEmployers("plus")}
                  >
                    {state?.["Plusieurs employeurs"] && (
                      <div className="no-checkbox-mark">
                        <img
                          src="/checkMarkIcon.png"
                          alt="checkmark"
                          className="check-mark-icon"
                        />
                      </div>
                    )}
                  </div>
                  <p className="no">{t("more")}</p>
                </div>
              </div>
              <h6
                className="input-title"
                style={{ marginTop: "0", padding: "10px 0" }}
              >
                {t("trefundstep2.fields.actrel")}
              </h6>
            </div>
          )}

          {state?.activites?.length > 0 && (
            <div style={{ position: "relative" }}>
              <ValidationError
                error={step2ValidationErrors["activites"]}
                message="Required fields"
                topPosition={"-20px"}
              />
              {state?.activites.map((item, index) => {
                return (
                  <ActivityInputs
                    key={item.id}
                    activity={item}
                    onChange={onActivityChange}
                    onDeleteActivity={onDeleteActivity}
                    index={index}
                  />
                );
              })}
            </div>
          )}
          <div
            className="no-checkbox-block"
            style={{ marginBottom: "10px", position: "relative" }}
          >
            <ValidationError
              error={step2ValidationErrors["Allocations familiales type"]}
              message="Required"
              topPosition={"110%"}
            />
            <div
              className="no-checkbox"
              onClick={() => handleSelectIncomeSource("Allocations familiales")}
            >
              {state?.["Sélections des revenus"]?.[
                "Allocations familiales"
              ] && (
                <div className="no-checkbox-mark">
                  <img
                    src="/checkMarkIcon.png"
                    alt="checkmark"
                    className="check-mark-icon"
                  />
                </div>
              )}
            </div>
            <p className="no">{t("trefundstep2.fields.famal")}</p>
          </div>
          {state?.["Sélections des revenus"]?.["Allocations familiales"] && (
            <div
              className="single-parent-hausehold-boxes "
              style={{ paddingLeft: "20px", marginBottom: "20px" }}
            >
              <div
                className="no-checkbox-block"
                style={{ marginBottom: "10px", marginRight: "20px" }}
              >
                <div
                  className="no-checkbox"
                  onClick={() =>
                    handleSelectFamilyAllowances("Versees par employeur")
                  }
                >
                  {state?.["Allocations familiales type"]?.type ===
                    "Versees par employeur" && (
                    <div className="no-checkbox-mark">
                      <img
                        src="/checkMarkIcon.png"
                        alt="checkmark"
                        className="check-mark-icon"
                      />
                    </div>
                  )}
                </div>
                <p className="no">{t("trefundstep2.fields.payedbyemp")}</p>
              </div>
              <div
                className="no-checkbox-block"
                style={{ marginBottom: "10px" }}
              >
                <div
                  className="no-checkbox"
                  onClick={() =>
                    handleSelectFamilyAllowances(
                      "Versees directement par la CAF"
                    )
                  }
                >
                  {state?.["Allocations familiales type"]?.type ===
                    "Versees directement par la CAF" && (
                    <div className="no-checkbox-mark">
                      <img
                        src="/checkMarkIcon.png"
                        alt="checkmark"
                        className="check-mark-icon"
                      />
                    </div>
                  )}
                </div>
                <p className="no">{t("trefundstep2.fields.payedbucaf")}</p>
              </div>
            </div>
          )}
          <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
            <div
              className="no-checkbox"
              onClick={() => handleSelectIncomeSource("Activité indépendante")}
            >
              {state?.["Sélections des revenus"]?.["Activité indépendante"] && (
                <div className="no-checkbox-mark">
                  <img
                    src="/checkMarkIcon.png"
                    alt="checkmark"
                    className="check-mark-icon"
                  />
                </div>
              )}
            </div>
            <p className="no">{t("trefundstep2.fields.indact")}</p>
          </div>
          <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
            <div
              className="no-checkbox"
              onClick={() => handleSelectIncomeSource("Indémnités")}
            >
              {state?.["Sélections des revenus"]?.["Indémnités"] && (
                <div className="no-checkbox-mark">
                  <img
                    src="/checkMarkIcon.png"
                    alt="checkmark"
                    className="check-mark-icon"
                  />
                </div>
              )}
            </div>
            <p className="no">{t("trefundstep2.fields.comp")}</p>
            <div className="info-icon">
              <img src="/infoIcon.png" alt="compensations info" />
              <p className="show-info" id="sick-leave">
                {t("compinfo")}
              </p>
            </div>
          </div>
          <div
            className="no-checkbox-block"
            style={{ marginBottom: "10px", position: "relative" }}
          >
            <ValidationError
              error={step2ValidationErrors["Rentes et pensions"]}
              message="Required"
              topPosition={"110%"}
            />
            <div
              className="no-checkbox"
              onClick={() => handleSelectIncomeSource("Rentes et pensions")}
            >
              {state?.["Sélections des revenus"]?.["Rentes et pensions"] && (
                <div className="no-checkbox-mark">
                  <img
                    src="/checkMarkIcon.png"
                    alt="checkmark"
                    className="check-mark-icon"
                  />
                </div>
              )}
            </div>
            <p className="no">{t("trefundstep2.fields.pens")}</p>
          </div>
          {state?.["Sélections des revenus"]?.["Rentes et pensions"] && (
            <div
              className="single-parent-hausehold-boxes "
              style={{ paddingLeft: "20px", marginBottom: "20px" }}
            >
              <div
                className="no-checkbox-block"
                style={{ marginBottom: "10px", marginRight: "20px" }}
              >
                <div
                  className="no-checkbox"
                  onClick={() => handleSelectAnnuitiesAndPension("1er pillier")}
                >
                  {state?.["Rentes et pensions type"]?.find(
                    (el) => el.value === "1er pillier"
                  ) && (
                    <div className="no-checkbox-mark">
                      <img
                        src="/checkMarkIcon.png"
                        alt="checkmark"
                        className="check-mark-icon"
                      />
                    </div>
                  )}
                </div>
                <p className="no">{t("trefundstep2.fields.1pil")}</p>
                <div className="info-icon">
                  <img src="/infoIcon.png" alt="annuities and pensions info" />
                  <p className="show-info" id="avs-and-annuities-pensions">
                    {t("avs")}
                  </p>
                </div>
              </div>
              <div
                className="no-checkbox-block"
                style={{ marginBottom: "10px" }}
              >
                <div
                  className="no-checkbox"
                  onClick={() =>
                    handleSelectAnnuitiesAndPension("2eme pillier")
                  }
                >
                  {state?.["Rentes et pensions type"]?.find(
                    (el) => el.value === "2eme pillier"
                  ) && (
                    <div className="no-checkbox-mark">
                      <img
                        src="/checkMarkIcon.png"
                        alt="checkmark"
                        className="check-mark-icon"
                      />
                    </div>
                  )}
                </div>
                <p className="no">{t("trefundstep2.fields.2pil")}</p>
                <div className="info-icon">
                  <img src="/infoIcon.png" alt="annuities and pensions info" />
                  <p className="show-info" id="LPP">
                    {t("lpp")}
                  </p>
                </div>
              </div>
              <div
                className="no-checkbox-block"
                style={{ marginBottom: "10px" }}
              >
                <div
                  className="no-checkbox"
                  onClick={() =>
                    handleSelectAnnuitiesAndPension("3eme pillier")
                  }
                >
                  {state?.["Rentes et pensions type"]?.find(
                    (el) => el.value === "3eme pillier"
                  ) && (
                    <div className="no-checkbox-mark">
                      <img
                        src="/checkMarkIcon.png"
                        alt="checkmark"
                        className="check-mark-icon"
                      />
                    </div>
                  )}
                </div>
                <p className="no">{t("trefundstep2.fields.3pil")}</p>
                <div className="info-icon">
                  <img src="/infoIcon.png" alt="" />
                  <p className="show-info" id="threeA">
                    {t("3a")}
                  </p>
                </div>
              </div>
            </div>
          )}
          <div className="no-checkbox-block">
            <div
              className="no-checkbox"
              onClick={() => handleSelectIncomeSource("Pensions alimentaires")}
            >
              {state?.["Sélections des revenus"]?.["Pensions alimentaires"] && (
                <div className="no-checkbox-mark">
                  <img
                    src="/checkMarkIcon.png"
                    alt="checkmark"
                    className="check-mark-icon"
                  />
                </div>
              )}
            </div>
            <p className="no">{t("trefundstep2.fields.alim")}</p>
          </div>
          {state?.["Sélections des revenus"]?.["Pensions alimentaires"] && (
            <div style={{ position: "relative" }}>
              <ValidationError
                error={
                  step2ValidationErrors[
                    "Sélections des revenus Pensions alimentaires"
                  ]
                }
                message="Required fields"
                topPosition={"-10px"}
              />
              <AlimonyInputs
                onChange={onAlimonyChange}
                incomeAlimony={
                  state?.["Sélections des revenus Pensions alimentaires"]
                }
              />
            </div>
          )}
        </div>
      </div>
      {state["Situation personnelle"]?.frenchValue === "Marié" && (
        <div className="checkboxes-block">
          <h4 className="checkboxes-title">
            {t("trefundstep2.fields.incomeselection")}
          </h4>
          {state["Situation personnelle"]?.frenchValue === "Marié" && (
            <p className="partner-label">{t("part2")}</p>
          )}

          <div
            className="single-parent-hausehold-boxes"
            style={{ position: "relative" }}
          >
            <ValidationError
              error={
                step2ValidationErrors["Sélections des revenus Contribuable2"]
              }
              topPosition="-15px"
              message="Required"
            />
            <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
              <div
                className="no-checkbox"
                onClick={() =>
                  handleSelectPartner2IncomeSource("Activites salariale")
                }
              >
                {state?.["Sélections des revenus Contribuable2"]?.[
                  "Activites salariale"
                ] && (
                  <div className="no-checkbox-mark">
                    <img
                      src="/checkMarkIcon.png"
                      alt="checkmark"
                      className="check-mark-icon"
                    />
                  </div>
                )}
              </div>
              <p className="no">{t("trefundstep2.fields.salact")}</p>
            </div>

            {state?.["Sélections des revenus Contribuable2"]?.[
              "Activites salariale"
            ] && (
              <div
                className="single-parent-hausehold-boxes horisontal"
                style={{ position: "relative" }}
              >
                <ValidationError
                  error={
                    step2ValidationErrors["type de Activité Contribuable2"]
                  }
                  topPosition={"-13px"}
                  message="Required"
                />
                <div
                  className="no-checkbox-block"
                  style={{ marginBottom: "10px", marginRight: "20px" }}
                >
                  <div
                    className="no-checkbox"
                    onClick={() =>
                      handleSelecPartner2tSalariedActivities("Principale", true)
                    }
                  >
                    {state?.["type de Activité Contribuable2"] ===
                      "Principale" && (
                      <div className="no-checkbox-mark">
                        <img
                          src="/checkMarkIcon.png"
                          alt="checkmark"
                          className="check-mark-icon"
                        />
                      </div>
                    )}
                  </div>
                  <p className="no">{t("trefundstep2.fields.main")}</p>
                </div>
                <div
                  className="no-checkbox-block"
                  style={{ marginBottom: "10px" }}
                >
                  <div
                    className="no-checkbox"
                    onClick={() =>
                      handleSelecPartner2tSalariedActivities(
                        "Accessoires",
                        true
                      )
                    }
                  >
                    {state?.["type de Activité Contribuable2"] ===
                      "Accessoires" && (
                      <div className="no-checkbox-mark">
                        <img
                          src="/checkMarkIcon.png"
                          alt="checkmark"
                          className="check-mark-icon"
                        />
                      </div>
                    )}
                  </div>
                  <p className="no">{t("trefundstep2.fields.accessory")}</p>
                </div>
              </div>
            )}
            {state?.["Sélections des revenus Contribuable2"]?.[
              "Activites salariale"
            ] && (
              <div style={{ marginBottom: "10px" }}>
                <h6 className="input-title" style={{ marginTop: "0" }}>
                  {t("trefundstep2.fields.mulemp")}
                </h6>
                <div className="single-parent-hausehold-boxes horisontal">
                  <div
                    className="no-checkbox-block"
                    style={{ marginBottom: "10px", marginRight: "20px" }}
                  >
                    <div
                      className="no-checkbox"
                      onClick={() => handleSetPartner2MultipleEmployers("no")}
                    >
                      {state?.["activites Contribuable2"]?.length === 1 && (
                        <div className="no-checkbox-mark">
                          <img
                            src="/checkMarkIcon.png"
                            alt="checkmark"
                            className="check-mark-icon"
                          />
                        </div>
                      )}
                    </div>
                    <p className="no">{t("no")}</p>
                  </div>
                  <div
                    className="no-checkbox-block"
                    style={{ marginBottom: "10px", marginRight: "20px" }}
                  >
                    <div
                      className="no-checkbox"
                      onClick={() => handleSetPartner2MultipleEmployers("2")}
                    >
                      {state?.["activites Contribuable2"]?.length === 2 && (
                        <div className="no-checkbox-mark">
                          <img
                            src="/checkMarkIcon.png"
                            alt="checkmark"
                            className="check-mark-icon"
                          />
                        </div>
                      )}
                    </div>
                    <p className="no">2</p>
                  </div>
                  <div
                    className="no-checkbox-block"
                    style={{ marginBottom: "10px", marginRight: "20px" }}
                  >
                    <div
                      className="no-checkbox"
                      onClick={() => handleSetPartner2MultipleEmployers("3")}
                    >
                      {state?.["activites Contribuable2"]?.length === 3 && (
                        <div className="no-checkbox-mark">
                          <img
                            src="/checkMarkIcon.png"
                            alt="checkmark"
                            className="check-mark-icon"
                          />
                        </div>
                      )}
                    </div>
                    <p className="no">3</p>
                  </div>
                  <div
                    className="no-checkbox-block"
                    style={{ marginBottom: "10px" }}
                  >
                    <div
                      className="no-checkbox"
                      onClick={() => handleSetPartner2MultipleEmployers("plus")}
                    >
                      {state?.["Plusieurs employeurs Contribuable2"] && (
                        <div className="no-checkbox-mark">
                          <img
                            src="/checkMarkIcon.png"
                            alt="checkmark"
                            className="check-mark-icon"
                          />
                        </div>
                      )}
                    </div>
                    <p className="no">{t("more")}</p>
                  </div>
                </div>
                <h6
                  className="input-title"
                  style={{ marginTop: "0", padding: "10px 0" }}
                >
                  {t("trefundstep2.fields.actrel")}
                </h6>
              </div>
            )}
            {state?.["activites Contribuable2"]?.length > 0 && (
              <div style={{ position: "relative" }}>
                <ValidationError
                  error={step2ValidationErrors["activites Contribuable2"]}
                  topPosition={"-20px"}
                />
                {state?.["activites Contribuable2"].map((item, index) => {
                  return (
                    <ActivityInputs
                      key={item.id}
                      activity={item}
                      onChange={onContribuable2ActivityChange}
                      onDeleteActivity={onDeleteActivity}
                      index={index}
                    />
                  );
                })}
              </div>
            )}
            <div
              className="no-checkbox-block"
              style={{ marginBottom: "10px", position: "relative" }}
            >
              <ValidationError
                error={
                  step2ValidationErrors[
                    "Allocations familiales type Contribuable2"
                  ]
                }
                message="Required"
                topPosition={"110%"}
              />
              <div
                className="no-checkbox"
                onClick={() =>
                  handleSelectPartner2IncomeSource("Allocations familiales")
                }
              >
                {state?.["Sélections des revenus Contribuable2"]?.[
                  "Allocations familiales"
                ] && (
                  <div className="no-checkbox-mark">
                    <img
                      src="/checkMarkIcon.png"
                      alt="checkmark"
                      className="check-mark-icon"
                    />
                  </div>
                )}
              </div>
              <p className="no">{t("trefundstep2.fields.famal")}</p>
            </div>
            {state?.["Sélections des revenus Contribuable2"]?.[
              "Allocations familiales"
            ] && (
              <div
                className="single-parent-hausehold-boxes "
                style={{ paddingLeft: "20px", marginBottom: "20px" }}
              >
                <div
                  className="no-checkbox-block"
                  style={{ marginBottom: "10px", marginRight: "20px" }}
                >
                  <div
                    className="no-checkbox"
                    onClick={() =>
                      handleSelectPartner2FamilyAllowances(
                        "Versees par employeur"
                      )
                    }
                  >
                    {state?.["Allocations familiales type Contribuable2"]
                      ?.type === "Versees par employeur" && (
                      <div className="no-checkbox-mark">
                        <img
                          src="/checkMarkIcon.png"
                          alt="checkmark"
                          className="check-mark-icon"
                        />
                      </div>
                    )}
                  </div>
                  <p className="no">{t("trefundstep2.fields.payedbyemp")}</p>
                </div>
                <div
                  className="no-checkbox-block"
                  style={{ marginBottom: "10px" }}
                >
                  <div
                    className="no-checkbox"
                    onClick={() =>
                      handleSelectPartner2FamilyAllowances(
                        "Versees directement par la CAF"
                      )
                    }
                  >
                    {state?.["Allocations familiales type Contribuable2"]
                      ?.type === "Versees directement par la CAF" && (
                      <div className="no-checkbox-mark">
                        <img
                          src="/checkMarkIcon.png"
                          alt="checkmark"
                          className="check-mark-icon"
                        />
                      </div>
                    )}
                  </div>
                  <p className="no">{t("trefundstep2.fields.payedbucaf")}</p>
                </div>
              </div>
            )}
            <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
              <div
                className="no-checkbox"
                onClick={() =>
                  handleSelectPartner2IncomeSource("Activité indépendante")
                }
              >
                {state?.["Sélections des revenus Contribuable2"]?.[
                  "Activité indépendante"
                ] && (
                  <div className="no-checkbox-mark">
                    <img
                      src="/checkMarkIcon.png"
                      alt="checkmark"
                      className="check-mark-icon"
                    />
                  </div>
                )}
              </div>
              <p className="no">{t("trefundstep2.fields.indact")}</p>
            </div>
            <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
              <div
                className="no-checkbox"
                onClick={() => handleSelectPartner2IncomeSource("Indémnités")}
              >
                {state?.["Sélections des revenus Contribuable2"]?.[
                  "Indémnités"
                ] && (
                  <div className="no-checkbox-mark">
                    <img
                      src="/checkMarkIcon.png"
                      alt="checkmark"
                      className="check-mark-icon"
                    />
                  </div>
                )}
              </div>
              <p className="no">{t("trefundstep2.fields.comp")}</p>
              <div className="info-icon">
                <img src="/infoIcon.png" alt="compensations info" />
                <p className="show-info" id="sick-leave">
                  {t("compinfo")}
                </p>
              </div>
            </div>
            <div
              className="no-checkbox-block"
              style={{ marginBottom: "10px", position: "relative" }}
            >
              <ValidationError
                error={
                  step2ValidationErrors["Rentes et pensions type Contribuable2"]
                }
                message="Required"
                topPosition={"110%"}
              />
              <div
                className="no-checkbox"
                onClick={() =>
                  handleSelectPartner2IncomeSource("Rentes et pensions")
                }
              >
                {state?.["Sélections des revenus Contribuable2"]?.[
                  "Rentes et pensions"
                ] && (
                  <div className="no-checkbox-mark">
                    <img
                      src="/checkMarkIcon.png"
                      alt="checkmark"
                      className="check-mark-icon"
                    />
                  </div>
                )}
              </div>
              <p className="no">{t("trefundstep2.fields.pens")}</p>
            </div>
            {state?.["Sélections des revenus Contribuable2"]?.[
              "Rentes et pensions"
            ] && (
              <div
                className="single-parent-hausehold-boxes "
                style={{ paddingLeft: "20px", marginBottom: "20px" }}
              >
                <div
                  className="no-checkbox-block"
                  style={{ marginBottom: "10px", marginRight: "20px" }}
                >
                  <div
                    className="no-checkbox"
                    onClick={() =>
                      handleSelectPartner2AnnuitiesAndPension("1er pillier")
                    }
                  >
                    {state?.["Rentes et pensions type Contribuable2"]?.find(
                      (el) => el.value === "1er pillier"
                    ) && (
                      <div className="no-checkbox-mark">
                        <img
                          src="/checkMarkIcon.png"
                          alt="checkmark"
                          className="check-mark-icon"
                        />
                      </div>
                    )}
                  </div>
                  <p className="no">{t("trefundstep2.fields.1pil")}</p>
                  <div className="info-icon">
                    <img
                      src="/infoIcon.png"
                      alt="annuities and pensions info"
                    />
                    <p className="show-info" id="avs-and-annuities-pensions">
                      {t("avs")}
                    </p>
                  </div>
                </div>
                <div
                  className="no-checkbox-block"
                  style={{ marginBottom: "10px" }}
                >
                  <div
                    className="no-checkbox"
                    onClick={() =>
                      handleSelectPartner2AnnuitiesAndPension("2eme pillier")
                    }
                  >
                    {state?.["Rentes et pensions type Contribuable2"]?.find(
                      (el) => el.value === "2eme pillier"
                    ) && (
                      <div className="no-checkbox-mark">
                        <img
                          src="/checkMarkIcon.png"
                          alt="checkmark"
                          className="check-mark-icon"
                        />
                      </div>
                    )}
                  </div>
                  <p className="no">{t("trefundstep2.fields.2pil")}</p>
                  <div className="info-icon">
                    <img
                      src="/infoIcon.png"
                      alt="annuities and pensions info"
                    />
                    <p className="show-info" id="LPP">
                      {t("lpp")}
                    </p>
                  </div>
                </div>
                <div
                  className="no-checkbox-block"
                  style={{ marginBottom: "10px" }}
                >
                  <div
                    className="no-checkbox"
                    onClick={() =>
                      handleSelectPartner2AnnuitiesAndPension("3eme pillier")
                    }
                  >
                    {state?.["Rentes et pensions type Contribuable2"]?.find(
                      (el) => el.value === "3eme pillier"
                    ) && (
                      <div className="no-checkbox-mark">
                        <img
                          src="/checkMarkIcon.png"
                          alt="checkmark"
                          className="check-mark-icon"
                        />
                      </div>
                    )}
                  </div>
                  <p className="no">{t("trefundstep2.fields.3pil")}</p>
                  <div className="info-icon">
                    <img src="/infoIcon.png" alt="" />
                    <p className="show-info" id="threeA">
                      {t("3a")}
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div className="no-checkbox-block">
              <div
                className="no-checkbox"
                onClick={() =>
                  handleSelectPartner2IncomeSource("Pensions alimentaires")
                }
              >
                {state?.["Sélections des revenus Contribuable2"]?.[
                  "Pensions alimentaires"
                ] && (
                  <div className="no-checkbox-mark">
                    <img
                      src="/checkMarkIcon.png"
                      alt="checkmark"
                      className="check-mark-icon"
                    />
                  </div>
                )}
              </div>
              <p className="no">{t("trefundstep2.fields.alim")}</p>
            </div>
            {state?.["Sélections des revenus Contribuable2"]?.[
              "Pensions alimentaires"
            ] && (
              <div style={{ position: "relative" }}>
                <AlimonyInputs
                  onChange={onAlimonyChangePartner2}
                  incomeAlimony={
                    state?.[
                      "Sélections des revenus Pensions alimentaires Contribuable2"
                    ]
                  }
                />
                <ValidationError
                  error={
                    step2ValidationErrors[
                      "Sélections des revenus Pensions alimentaires Contribuable2"
                    ]
                  }
                  message="Required fields"
                  topPosition={"-10px"}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Step2;
