import React, { useEffect, useState } from "react";
import "./taxrefund.css";
import { useAppState } from "../../contexts/formContext";
import CustomSelect from "../Customselect";
import DependentChildren from "../DependentChildren";
import Step2 from "../Step2";
import Step3 from "../Step3";
import Step4 from "../Step4";
import FileSubmitter from "../FileSubmitter";
import Payment from "../Payment";
import { useNavigate } from "react-router";
import axios from "axios";
import ValidationError from "../validationError";
import { useBeforeunload } from "react-beforeunload";
import { useTranslation } from "react-i18next";

function TaxRefund(props) {
  const marriageStatusOptions = [
    { id: 1, value: "single", frenchValue: "Célibataire" },
    { id: 2, value: "married", frenchValue: "Marié" },
    { id: 3, value: "divorced", frenchValue: "Divorcé / séparé" },
    { id: 4, value: "widoved", frenchValue: "Veuf / Veuve" },
    { id: 5, value: "student", frenchValue: "Etudiant" },
  ];
  const [t, i18n] = useTranslation("global");
  const professionalSituationOptions = [
    { id: 1, value: "Retired", frenchValue: "Retraité" },
    { id: 2, value: "Without activity", frenchValue: "Sans activité" },
    { id: 3, value: "Student/Apprentice", frenchValue: "Etudiant/Apprenti" },
    { id: 4, value: "Independent", frenchValue: "Indépendant" },
    { id: 5, value: "Employed", frenchValue: "Salarié" },
  ];

  const [state, setState] = useAppState();

  const [fileList, setFileList] = useState([]);
  const [step1ValidationErrors, setStep1ValidationErrors] = useState({});
  const [step2ValidationErrors, setStep2ValidationErrors] = useState({});
  const [step3ValidationErrors, setStep3ValidationErrors] = useState({});
  const [step4ValidationErrors, setStep4ValidationErrors] = useState({});
  const [filesValidationErrors, setFilesValidationErrors] = useState({});
  const [emailError, setEmailError] = useState();
  const [
    personalInformationValidationErrors,
    setPersonalInformationValidationErrors,
  ] = useState({});
  const [showOptions, setShowOptions] = useState(false);
  const [showProfessionalStatusOptions, setShowProfessionalStatusOptions] =
    useState(false);
  const [
    showSpouseProfessionalStatusOptions,
    setShowSpouseProfessionalStatusOptions,
  ] = useState(false);

  const [localPrice, setLocalPrice] = useState(0);

  const navigate = useNavigate();

  useBeforeunload(
    state && state?.["Situation personnelle"]
      ? (event) => event.preventDefault()
      : null
  );

  const onChange = (item) => {
    invalidateStep1Errors("Situation personnelle");
    setState((state) => {
      if (item.frenchValue !== "Marié") {
        const {
          ["Spouse Situation professionnelle"]: spsut,
          ["Sélections des revenus Contribuable2"]: inc,
          ["Sélections des revenus Pensions alimentaires Contribuable2"]: al,
          ["Rentes et pensions type Contribuable2"]: ren,
          ["Allocations familiales type Contribuable2"]: fam,
          ["activites Contribuable2"]: act,
          ["type de Activité Contribuable2"]: typ,
          ["Plusieurs employeurs Contribuable2"]: plEm,
          ["frais de transport Contribuable2"]: tr,
          ["Sélections des fortune Contribuable2"]: sf,
          ["combien comptes bancaires Contribuable2"]: ccb,
          ["combien assurance 3b Contribuable2"]: ca3,
          ["frais de perfectionnement montant Contribuable2"]: fdp,
          ["frais de perfectionnement Contribuable2"]: fp,
          ...rest
        } = state;
        return { ...rest, ["Situation personnelle"]: item };
      } else {
        return { ...state, ["Situation personnelle"]: item };
      }
    });

    setShowOptions(false);
  };

  const invalidateStep1Errors = (key) => {
    setStep1ValidationErrors((errors) => {
      const { [key]: err, ...rest } = errors;
      return { ...rest };
    });
  };

  const invalidateStep2Errors = (key) => {
    setStep2ValidationErrors((errors) => {
      const { [key]: err, ...rest } = errors;
      return { ...rest };
    });
  };

  const invalidateStep3Errors = (key) => {
    setStep3ValidationErrors((errors) => {
      const { [key]: err, ...rest } = errors;
      return { ...rest };
    });
  };

  const invalidateStep4Errors = (key) => {
    setStep4ValidationErrors((errors) => {
      const { [key]: err, ...rest } = errors;
      return { ...rest };
    });
  };

  const invalidateFilesErrors = (key) => {
    setFilesValidationErrors((errors) => {
      const { [key]: err, ...rest } = errors;
      return { ...rest };
    });
  };

  const invalidatePersonalInformation = () => {
    setPersonalInformationValidationErrors({});
  };

  const handleResetValue = () => {
    setState((state) => {
      if (state?.spouseProfessionalSituation) {
        const { spouseProfessionalSituation, ...rest } = state;
        return { ...rest, ["personalSituation"]: null };
      } else {
        return { ...state, ["personalSituation"]: null };
      }
    });
    setShowOptions(true);
  };

  const handleResetProfessionalSituation = () => {
    setState((state) => {
      return { ...state, ["Situation professionnelle"]: null };
    });
    setShowProfessionalStatusOptions(true);
  };

  const handleChangeProfessionalSituation = (item) => {
    invalidateStep1Errors("Situation professionnelle");
    setState((state) => {
      return { ...state, "Situation professionnelle": item };
    });
    setShowProfessionalStatusOptions(false);
  };

  const handleChangeSpouseProfessionalSituation = (item) => {
    invalidateStep1Errors("Spouse Situation professionnelle");
    setState((state) => {
      return { ...state, "Spouse Situation professionnelle": item };
    });
    setShowSpouseProfessionalStatusOptions(false);
  };

  const handleResetSpouseProfessionalSituation = () => {
    setState((state) => {
      return { ...state, ["Spouse Situation professionnelle"]: null };
    });
    setShowSpouseProfessionalStatusOptions(true);
  };

  const handleNextStep = () => {
    if (checkInvalidFields(state) || checkPrice(state)) {
      return;
    }

    const currentStep = state.step;

    if (currentStep === 1 && validateStep1(state) === true) {
      setState((state) => {
        return { ...state, step: currentStep + 1 };
      });
    }
    if (currentStep === 2 && validateStep2(state) === true) {
      setState((state) => {
        return { ...state, step: currentStep + 1 };
      });
    }
    if (currentStep === 3 && validateStep3(state) === true) {
      setState((state) => {
        return { ...state, step: currentStep + 1 };
      });
    }
    if (
      currentStep === 4 &&
      validateStep4(state) === true &&
      validatePersonalInformation(state?.personalInformation) === true &&
      validateEmail(state?.personalInformation) === true
    ) {
      setState((state) => {
        return { ...state, step: currentStep + 1 };
      });
    }

    if (currentStep === 5 && validateFiles(state) === true) {
      setState((state) => {
        return { ...state, step: currentStep + 1 };
      });
    }
  };

  const handlePreviousStep = () => {
    const currentStep = state.step;
    setState((state) => {
      if (currentStep === 1) {
        return;
      } else {
        return { ...state, step: currentStep - 1 };
      }
    });
  };

  const calculatePrice = (form) => {
    let price = 0;
    const personalSituation = [
      { value: "Célibataire", price: "40" },
      { value: "Marié", price: "50" },
      { value: "Divorcé / séparé", price: "40" },
      { value: "Veuf / Veuve", price: "40" },
      { value: "Etudiant", price: "40" },
    ];

    const incomeSource = [
      { value: "Indémnités", price: "10" },
      { value: "Rentes et pensions", price: "10" },
      { value: "Pensions alimentaires", price: "20" },
    ];

    personalSituation.forEach((item) => {
      if (form["Situation personnelle"]?.frenchValue === item.value) {
        price = price + +item.price;
      }
    });

    if (Array.isArray(state["Enfants a scharge"])) {
      const childrenPrice = state["Enfants a scharge"].length * 10;
      price = price + childrenPrice;
    }

    incomeSource.forEach((item) => {
      if (form["Sélections des revenus"]?.[item.value]) {
        price = price + +item.price;
      }
    });

    if (
      state["Allocations familiales type"]?.type ===
      "Versees directement par la CAF"
    ) {
      price = price + 10;
    }

    if (
      state["Allocations familiales type Contribuable2"]?.type ===
      "Versees directement par la CAF"
    ) {
      price = price + 10;
    }

    if (state["Sélections des revenus Contribuable2"]) {
      incomeSource.forEach((item) => {
        if (form["Sélections des revenus Contribuable2"]?.[item.value]) {
          price = price + +item.price;
        }
      });
    }

    if (Array.isArray(state?.activites)) {
      const activitiesPrice = state.activites.length * 15;
      price = price + activitiesPrice;
    }

    if (Array.isArray(state?.["activites Contribuable2"])) {
      const activitiesPrice = state["activites Contribuable2"].length * 15;
      price = price + activitiesPrice;
    }

    if (Array.isArray(state?.["frais de transport"])) {
      const activitiesPrice = state["frais de transport"].length * 5;
      price = price + activitiesPrice;
    }

    if (Array.isArray(state?.["frais de transport Contribuable2"])) {
      const activitiesPrice =
        state["frais de transport Contribuable2"].length * 5;
      price = price + activitiesPrice;
    }

    if (state?.["frais de repas"]) {
      price = price + 5;
    }

    if (state?.["frais de repas Contribuable2"]) {
      price = price + 5;
    }

    if (state?.["frais de perfectionnement"] === "oui") {
      price = price + 5;
    }

    if (state?.["frais de perfectionnement Contribuable2"] === "oui") {
      price = price + 5;
    }

    if (
      state?.["combien comptes bancaires"]?.number &&
      state?.["combien comptes bancaires"]?.number !== "Plus de 4"
    ) {
      const bancAccountsPrice = +state["combien comptes bancaires"].number * 10;
      price = price + bancAccountsPrice;
    }

    if (
      state?.["combien comptes bancaires Contribuable2"]?.number &&
      state?.["combien comptes bancaires Contribuable2"]?.number !== "Plus de 4"
    ) {
      const bancAccountsPrice =
        +state["combien comptes bancaires Contribuable2"].number * 10;
      price = price + bancAccountsPrice;
    }

    if (
      state?.["combien assurance 3b"]?.number &&
      state?.["combien assurance 3b"]?.number !== "Plus de 4"
    ) {
      const assurance3bPrice = +state["combien assurance 3b"].number * 10;
      price = price + assurance3bPrice;
    }

    if (
      state?.["combien assurance 3b Contribuable2"]?.number &&
      state?.["combien assurance 3b Contribuable2"]?.number !== "Plus de 4"
    ) {
      const assurance3bPrice =
        +state["combien assurance 3b Contribuable2"].number * 10;
      price = price + assurance3bPrice;
    }

    if (
      state?.["combien OPP3"]?.number &&
      state?.["combien OPP3"]?.number !== "Plus de 4"
    ) {
      const opp3Price = +state["combien OPP3"].number * 10;
      price = price + opp3Price;
    }

    if (
      state?.["combien OPP3 Contribuable2"]?.number &&
      state?.["combien OPP3 Contribuable2"]?.number !== "Plus de 4"
    ) {
      const opp3Price = +state["combien OPP3 Contribuable2"].number * 10;
      price = price + opp3Price;
    }

    if (
      state?.["Combien Frais médicaux et dentaires"]?.number &&
      state?.["Combien Frais médicaux et dentaires"]?.number !== "Plus de 4"
    ) {
      const medicalAndDentalPrice =
        +state["Combien Frais médicaux et dentaires"].number * 10;
      price = price + medicalAndDentalPrice;
    }

    if (
      state?.["Combien Frais médicaux et dentaires Contribuable2"]?.number &&
      state?.["Combien Frais médicaux et dentaires Contribuable2"]?.number !==
        "Plus de 4"
    ) {
      const medicalAndDentalPrice =
        +state["Combien Frais médicaux et dentaires Contribuable2"].number * 10;
      price = price + medicalAndDentalPrice;
    }

    if (
      state?.["Combien Dons"]?.number &&
      state?.["Combien Dons"]?.number !== "Plus de 4"
    ) {
      const donationsPrice = +state["Combien Dons"].number * 10;
      price = price + donationsPrice;
    }

    if (
      state?.["Combien Dons Contribuable2"]?.number &&
      state?.["Combien Dons Contribuable2"]?.number !== "Plus de 4"
    ) {
      const donationsPrice = +state["Combien Dons Contribuable2"].number * 10;
      price = price + donationsPrice;
    }

    if (
      state?.["Combien Assurances maladie et accidents"]?.number &&
      state?.["Combien Assurances maladie et accidents"]?.number !== "Plus de 4"
    ) {
      const assurance3bPrice =
        +state["Combien Assurances maladie et accidents"].number * 10;
      price = price + assurance3bPrice;
    }

    if (
      state?.["Combien Assurances maladie et accidents Contribuable2"]
        ?.number &&
      state?.["Combien Assurances maladie et accidents Contribuable2"]
        ?.number !== "Plus de 4"
    ) {
      const assurance3bPrice =
        +state["Combien Assurances maladie et accidents Contribuable2"].number *
        10;
      price = price + assurance3bPrice;
    }

    const deductionSelection = [
      { value: "Rachats dannées dassurances", price: "15" },
      { value: "Pensions alimentaires", price: "15" },

      { value: "Déductions logement", price: "10" },
      { value: "Frais de garde", price: "10" },
    ];

    deductionSelection.forEach((item) => {
      if (form["Sélections de déductions"]?.[item.value]) {
        price = price + +item.price;
      }
    });

    deductionSelection.forEach((item) => {
      if (form["Sélections de déductions Contribuable2"]?.[item.value]) {
        price = price + +item.price;
      }
    });

    setLocalPrice(price);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    calculatePrice(state);
  }, [state]);

  useEffect(() => {
    setState((state) => {
      return { ...state, price: localPrice };
    });
  }, [localPrice]);

  const validateStep1 = (form) => {
    let result = true;
    const requiredFields = [
      "Situation personnelle",
      "Situation professionnelle",
      "Enfants a scharge",
    ];

    requiredFields.forEach((field) => {
      if (!form[field]) {
        setStep1ValidationErrors((errors) => {
          return { ...errors, [field]: field };
        });
        result = false;
      }
    });

    if (state["Situation personnelle"]?.frenchValue === "Marié") {
      if (!state["Spouse Situation professionnelle"]) {
        setStep1ValidationErrors((errors) => {
          return {
            ...errors,
            ["Spouse Situation professionnelle"]:
              "Spouse Situation professionnelle",
          };
        });
        result = false;
      }
    }
    if (Array.isArray(state["Enfants a scharge"])) {
      if (!state["independendant seul avec enfant"]) {
        setStep1ValidationErrors((errors) => {
          return {
            ...errors,
            ["independendant seul avec enfant"]:
              "independendant seul avec enfant",
          };
        });
        result = false;
      }
      state["Enfants a scharge"].forEach((child) => {
        if (!child["Annee de naissance"] || !child["statut"]) {
          setStep1ValidationErrors((errors) => {
            return {
              ...errors,
              ["Enfants a scharge data"]: "Enfants a scharge data",
            };
          });
          result = false;
        }
      });
    }

    return result;
  };

  const validateStep2 = (form) => {
    let result = true;
    const requiredFields = ["canton de résidence", "Sélections des revenus"];
    requiredFields.forEach((field) => {
      if (!form[field]) {
        result = false;
        setStep2ValidationErrors((errors) => {
          return { ...errors, [field]: field };
        });
      }
    });

    if (state["Sélections des revenus"]?.["Activites salariale"]) {
      if (!state["type de Activité"]) {
        result = false;
        setStep2ValidationErrors((errors) => {
          return { ...errors, "type de Activité": "type de Activité" };
        });
      }
    }

    if (state["Sélections des revenus"]?.["Activites salariale"]) {
      if (!state["type de Activité"]) {
        result = false;
        setStep2ValidationErrors((errors) => {
          return { ...errors, "type de Activité": "type de Activité" };
        });
      }
    }

    if (
      state["Sélections des revenus Contribuable2"]?.["Activites salariale"]
    ) {
      if (!state["type de Activité Contribuable2"]) {
        result = false;
        setStep2ValidationErrors((errors) => {
          return {
            ...errors,
            "type de Activité Contribuable2": "type de Activité Contribuable2",
          };
        });
      }
    }

    if (state["Situation personnelle"]?.frenchValue === "Marié") {
      if (!state["Sélections des revenus Contribuable2"]) {
        result = false;
        setStep2ValidationErrors((errors) => {
          return {
            ...errors,
            "Sélections des revenus Contribuable2":
              "Sélections des revenus Contribuable2",
          };
        });
      }
    }

    if (Array.isArray(state["activites"])) {
      state["activites"].forEach((activity) => {
        if (!activity.name || !activity.taux) {
          setStep2ValidationErrors((errors) => {
            return { ...errors, activites: "activites" };
          });
          result = false;
        }
      });
    }

    if (Array.isArray(state["activites Contribuable2"])) {
      state["activites Contribuable2"].forEach((activity) => {
        if (!activity.name || !activity.taux) {
          setStep2ValidationErrors((errors) => {
            return {
              ...errors,
              "activites Contribuable2": "activites Contribuable2",
            };
          });
          result = false;
        }
      });
    }

    if (state["Sélections des revenus"]?.["Allocations familiales"]) {
      if (!state["Allocations familiales type"]) {
        setStep2ValidationErrors((errors) => {
          return {
            ...errors,
            "Allocations familiales type": "Allocations familiales type",
          };
        });
        result = false;
      }
    }

    if (
      state["Sélections des revenus Contribuable2"]?.["Allocations familiales"]
    ) {
      if (!state["Allocations familiales type Contribuable2"]) {
        setStep2ValidationErrors((errors) => {
          return {
            ...errors,
            "Allocations familiales type Contribuable2":
              "Allocations familiales type Contribuable2",
          };
        });
        result = false;
      }
    }

    if (state["Sélections des revenus"]?.["Rentes et pensions"]) {
      if (!state["Rentes et pensions type"]) {
        setStep2ValidationErrors((errors) => {
          return { ...errors, "Rentes et pensions": "Rentes et pensions" };
        });
        result = false;
      }
    }

    if (state["Sélections des revenus Contribuable2"]?.["Rentes et pensions"]) {
      if (!state["Rentes et pensions type Contribuable2"]) {
        setStep2ValidationErrors((errors) => {
          return {
            ...errors,
            "Rentes et pensions type Contribuable2":
              "Rentes et pensions type Contribuable2",
          };
        });
        result = false;
      }
    }

    if (state["Sélections des revenus"]?.["Pensions alimentaires"]) {
      if (
        !state["Sélections des revenus Pensions alimentaires"]?.["Nom/Prenom"]
      ) {
        setStep2ValidationErrors((errors) => {
          return {
            ...errors,
            "Sélections des revenus Pensions alimentaires":
              "Sélections des revenus Pensions alimentaires",
          };
        });
        result = false;
      }
      if (!state["Sélections des revenus Pensions alimentaires"]?.["Montant"]) {
        setStep2ValidationErrors((errors) => {
          return {
            ...errors,
            "Sélections des revenus Pensions alimentaires":
              "Sélections des revenus Pensions alimentaires",
          };
        });
        result = false;
      }
      if (
        !state["Sélections des revenus Pensions alimentaires"]?.["Domicile"]
      ) {
        setStep2ValidationErrors((errors) => {
          return {
            ...errors,
            "Sélections des revenus Pensions alimentaires":
              "Sélections des revenus Pensions alimentaires",
          };
        });
        result = false;
      }
      if (!state["Sélections des revenus Pensions alimentaires"]?.["Adresse"]) {
        setStep2ValidationErrors((errors) => {
          return {
            ...errors,
            "Sélections des revenus Pensions alimentaires":
              "Sélections des revenus Pensions alimentaires",
          };
        });
        result = false;
      }
    }

    if (
      state["Sélections des revenus Contribuable2"]?.["Pensions alimentaires"]
    ) {
      if (
        !state["Sélections des revenus Pensions alimentaires Contribuable2"]?.[
          "Nom/Prenom"
        ]
      ) {
        setStep2ValidationErrors((errors) => {
          return {
            ...errors,
            "Sélections des revenus Pensions alimentaires Contribuable2":
              "Sélections des revenus Pensions alimentaires Contribuable2",
          };
        });
        result = false;
      }
      if (
        !state["Sélections des revenus Pensions alimentaires Contribuable2"]?.[
          "Montant"
        ]
      ) {
        setStep2ValidationErrors((errors) => {
          return {
            ...errors,
            "Sélections des revenus Pensions alimentaires Contribuable2":
              "Sélections des revenus Pensions alimentaires Contribuable2",
          };
        });
        result = false;
      }
      if (
        !state["Sélections des revenus Pensions alimentaires Contribuable2"]?.[
          "Domicile"
        ]
      ) {
        setStep2ValidationErrors((errors) => {
          return {
            ...errors,
            "Sélections des revenus Pensions alimentaires Contribuable2":
              "Sélections des revenus Pensions alimentaires Contribuable2",
          };
        });
        result = false;
      }
      if (
        !state["Sélections des revenus Pensions alimentaires Contribuable2"]?.[
          "Adresse"
        ]
      ) {
        setStep2ValidationErrors((errors) => {
          return {
            ...errors,
            "Sélections des revenus Pensions alimentaires Contribuable2":
              "Sélections des revenus Pensions alimentaires Contribuable2",
          };
        });
        result = false;
      }
    }

    return result;
  };

  const validateStep3 = (form) => {
    let result = true;
    const requiredFields = [
      "frais de transport",
      "frais de repas",
      "frais de perfectionnement",
      "Sélections des fortune",
    ];

    const marriedRequiredFields = [
      "frais de transport Contribuable2",
      "frais de repas Contribuable2",
      "frais de perfectionnement Contribuable2",
      "Sélections des fortune Contribuable2",
    ];
    requiredFields.forEach((field) => {
      if (!form[field]) {
        setStep3ValidationErrors((errors) => {
          return { ...errors, [field]: field };
        });
        result = false;
      }
    });

    if (state["Situation personnelle"]?.frenchValue === "Marié") {
      marriedRequiredFields.forEach((field) => {
        if (!form[field]) {
          setStep3ValidationErrors((errors) => {
            return { ...errors, [field]: field };
          });
          result = false;
        }
      });
    }
    if (Array.isArray(state["frais de transport"])) {
      state["frais de transport"].forEach((transport) => {
        if (!transport["au"]) {
          setStep3ValidationErrors((errors) => {
            return {
              ...errors,
              "frais de transport data": "frais de transport data",
            };
          });
          result = false;
        }
        if (!transport["au"]) {
          setStep3ValidationErrors((errors) => {
            return {
              ...errors,
              "frais de transport data": "frais de transport data",
            };
          });
          result = false;
        }
        if (!transport["domicile"]) {
          setStep3ValidationErrors((errors) => {
            return {
              ...errors,
              "frais de transport data": "frais de transport data",
            };
          });
          result = false;
        }
        if (!transport["km par jour"]) {
          setStep3ValidationErrors((errors) => {
            return {
              ...errors,
              "frais de transport data": "frais de transport data",
            };
          });
          result = false;
        }
        if (!transport["lieu de travail"]) {
          setStep3ValidationErrors((errors) => {
            return {
              ...errors,
              "frais de transport data": "frais de transport data",
            };
          });
          result = false;
        }
        if (!transport["type"]) {
          setStep3ValidationErrors((errors) => {
            return {
              ...errors,
              "frais de transport data": "frais de transport data",
            };
          });
          result = false;
        }
      });
    }

    if (Array.isArray(state["frais de transport Contribuable2"])) {
      state["frais de transport Contribuable2"].forEach((transport) => {
        if (!transport["au"]) {
          setStep3ValidationErrors((errors) => {
            return {
              ...errors,
              "frais de transport Contribuable2 data":
                "frais de transport Contribuable2 data",
            };
          });
          result = false;
        }
        if (!transport["au"]) {
          setStep3ValidationErrors((errors) => {
            return {
              ...errors,
              "frais de transport Contribuable2 data":
                "frais de transport Contribuable2 data",
            };
          });
          result = false;
        }
        if (!transport["domicile"]) {
          setStep3ValidationErrors((errors) => {
            return {
              ...errors,
              "frais de transport Contribuable2 data":
                "frais de transport Contribuable2 data",
            };
          });
          result = false;
        }
        if (!transport["km par jour"]) {
          setStep3ValidationErrors((errors) => {
            return {
              ...errors,
              "frais de transport Contribuable2 data":
                "frais de transport Contribuable2 data",
            };
          });
          result = false;
        }
        if (!transport["lieu de travail"]) {
          setStep3ValidationErrors((errors) => {
            return {
              ...errors,
              "frais de transport Contribuable2 data":
                "frais de transport Contribuable2 data",
            };
          });
          result = false;
        }
        if (!transport["type"]) {
          setStep3ValidationErrors((errors) => {
            return {
              ...errors,
              "frais de transport Contribuable2 data":
                "frais de transport Contribuable2 data",
            };
          });
          result = false;
        }
      });
    }

    if (state["frais de perfectionnement Contribuable2"] === "oui") {
      if (!state["frais de perfectionnement montant Contribuable2"]) {
        setStep3ValidationErrors((errors) => {
          return {
            ...errors,
            "frais de perfectionnement montant Contribuable2":
              "frais de perfectionnement montant Contribuable2",
          };
        });
        result = false;
      }
    }

    if (state["frais de perfectionnement"] === "oui") {
      if (!state["frais de perfectionnement montant"]) {
        setStep3ValidationErrors((errors) => {
          return {
            ...errors,
            "frais de perfectionnement montant":
              "frais de perfectionnement montant",
          };
        });
        result = false;
      }
    }

    if (state["Sélections des fortune Contribuable2"]?.["Comptes Bancaires"]) {
      if (!state["combien comptes bancaires Contribuable2"]) {
        setStep3ValidationErrors((errors) => {
          return {
            ...errors,
            "combien comptes bancaires Contribuable2":
              "combien comptes bancaires Contribuable2",
          };
        });
        result = false;
      }
    }

    if (state["Sélections des fortune"]?.["Comptes Bancaires"]) {
      if (!state["combien comptes bancaires"]) {
        setStep3ValidationErrors((errors) => {
          return {
            ...errors,
            "combien comptes bancaires": "combien comptes bancaires",
          };
        });
        result = false;
      }
    }

    if (state["Sélections des fortune"]?.["Assurance 3B"]) {
      if (!state["combien assurance 3b"]) {
        setStep3ValidationErrors((errors) => {
          return { ...errors, "combien assurance 3b": "combien assurance 3b" };
        });
        result = false;
      }
    }

    if (state["Sélections des fortune Contribuable2"]?.["Assurance 3B"]) {
      if (!state["combien assurance 3b Contribuable2"]) {
        setStep3ValidationErrors((errors) => {
          return {
            ...errors,
            "combien assurance 3b Contribuable2":
              "combien assurance 3b Contribuable2",
          };
        });
        result = false;
      }
    }

    return result;
  };

  const validateStep4 = (form) => {
    let result = true;
    const requiredFields = ["Sélections de déductions"];
    const marriedRequiredFields = ["Sélections de déductions Contribuable2"];
    requiredFields.forEach((field) => {
      if (!form[field]) {
        setStep4ValidationErrors((errors) => {
          return {
            ...errors,
            [field]: field,
          };
        });
        result = false;
      }
    });

    if (state["Situation personnelle"]?.frenchValue === "Marié") {
      marriedRequiredFields.forEach((field) => {
        if (!form[field]) {
          setStep4ValidationErrors((errors) => {
            return {
              ...errors,
              [field]: field,
            };
          });
          result = false;
        }
      });
    }

    if (
      state["Sélections de déductions"]?.["Cotisatoin OPP3 (3ème pilier A)"]
    ) {
      if (!state["combien OPP3"]) {
        setStep4ValidationErrors((errors) => {
          return {
            ...errors,
            "combien OPP3": "combien OPP3",
          };
        });
        result = false;
      }
    }

    if (
      state["Sélections de déductions Contribuable2"]?.[
        "Cotisatoin OPP3 (3ème pilier A)"
      ]
    ) {
      if (!state["combien OPP3 Contribuable2"]) {
        setStep4ValidationErrors((errors) => {
          return {
            ...errors,
            "combien OPP3 Contribuable2": "combien OPP3 Contribuable2",
          };
        });
        result = false;
      }
    }

    if (state["Sélections de déductions"]?.["Frais médicaux et dentaires"]) {
      if (!state["Combien Frais médicaux et dentaires"]) {
        setStep4ValidationErrors((errors) => {
          return {
            ...errors,
            "Combien Frais médicaux et dentaires":
              "Combien Frais médicaux et dentaires",
          };
        });
        result = false;
      }
    }

    if (
      state["Sélections de déductions Contribuable2"]?.[
        "Frais médicaux et dentaires"
      ]
    ) {
      if (!state["Combien Frais médicaux et dentaires Contribuable2"]) {
        setStep4ValidationErrors((errors) => {
          return {
            ...errors,
            "Combien Frais médicaux et dentaires Contribuable2":
              "Combien Frais médicaux et dentaires Contribuable2",
          };
        });
        result = false;
      }
    }

    if (state["Sélections de déductions"]?.["Dons"]) {
      if (!state["Combien Dons"]) {
        setStep4ValidationErrors((errors) => {
          return {
            ...errors,
            "Combien Dons": "Combien Dons",
          };
        });
        result = false;
      }
    }

    if (state["Sélections de déductions Contribuable2"]?.["Dons"]) {
      if (!state["Combien Dons Contribuable2"]) {
        setStep4ValidationErrors((errors) => {
          return {
            ...errors,
            "Combien Dons Contribuable2": "Combien Dons Contribuable2",
          };
        });
        result = false;
      }
    }

    if (
      state["Sélections de déductions"]?.["Assurances maladie et accidents"]
    ) {
      if (!state["Combien Assurances maladie et accidents"]) {
        setStep4ValidationErrors((errors) => {
          return {
            ...errors,
            "Combien Assurances maladie et accidents":
              "Combien Assurances maladie et accidents",
          };
        });
        result = false;
      }
    }

    if (
      state["Sélections de déductions Contribuable2"]?.[
        "Assurances maladie et accidents"
      ]
    ) {
      if (!state["Combien Assurances maladie et accidents Contribuable2"]) {
        setStep4ValidationErrors((errors) => {
          return {
            ...errors,
            "Combien Assurances maladie et accidents Contribuable2":
              "Combien Assurances maladie et accidents Contribuable2",
          };
        });
        result = false;
      }
    }

    if (state["Sélections de déductions"]?.["Pensions alimentaires"]) {
      if (!state["Sélections des deductions Pensions alimentaires"]?.["Nom"]) {
        setStep4ValidationErrors((errors) => {
          return {
            ...errors,
            "Sélections des deductions Pensions alimentaires":
              "Sélections des deductions Pensions alimentaires",
          };
        });
        result = false;
      }
      if (
        !state["Sélections des deductions Pensions alimentaires"]?.["Prenom"]
      ) {
        setStep4ValidationErrors((errors) => {
          return {
            ...errors,
            "Sélections des deductions Pensions alimentaires":
              "Sélections des deductions Pensions alimentaires",
          };
        });
        result = false;
      }
      if (
        !state["Sélections des deductions Pensions alimentaires"]?.["Montant"]
      ) {
        setStep4ValidationErrors((errors) => {
          return {
            ...errors,
            "Sélections des deductions Pensions alimentaires":
              "Sélections des deductions Pensions alimentaires",
          };
        });
        result = false;
      }
      if (
        !state["Sélections des deductions Pensions alimentaires"]?.["Domicile"]
      ) {
        setStep4ValidationErrors((errors) => {
          return {
            ...errors,
            "Sélections des deductions Pensions alimentaires":
              "Sélections des deductions Pensions alimentaires",
          };
        });
        result = false;
      }
      if (
        !state["Sélections des deductions Pensions alimentaires"]?.["Adresse"]
      ) {
        setStep4ValidationErrors((errors) => {
          return {
            ...errors,
            "Sélections des deductions Pensions alimentaires":
              "Sélections des deductions Pensions alimentaires",
          };
        });
        result = false;
      }
      if (
        !state["Sélections des deductions Pensions alimentaires"]?.[
          "lien de parentes"
        ]
      ) {
        setStep4ValidationErrors((errors) => {
          return {
            ...errors,
            "Sélections des deductions Pensions alimentaires":
              "Sélections des deductions Pensions alimentaires",
          };
        });
        result = false;
      }
    }

    if (
      state["Sélections de déductions Contribuable2"]?.["Pensions alimentaires"]
    ) {
      if (
        !state[
          "Sélections des deductions Pensions alimentaires Contribuable2"
        ]?.["Nom"]
      ) {
        setStep4ValidationErrors((errors) => {
          return {
            ...errors,
            "Sélections des deductions Pensions alimentaires Contribuable2":
              "Sélections des deductions Pensions alimentaires Contribuable2",
          };
        });
        result = false;
      }
      if (
        !state[
          "Sélections des deductions Pensions alimentaires Contribuable2"
        ]?.["Prenom"]
      ) {
        setStep4ValidationErrors((errors) => {
          return {
            ...errors,
            "Sélections des deductions Pensions alimentaires Contribuable2":
              "Sélections des deductions Pensions alimentaires Contribuable2",
          };
        });
        result = false;
      }
      if (
        !state[
          "Sélections des deductions Pensions alimentaires Contribuable2"
        ]?.["Montant"]
      ) {
        setStep4ValidationErrors((errors) => {
          return {
            ...errors,
            "Sélections des deductions Pensions alimentaires Contribuable2":
              "Sélections des deductions Pensions alimentaires Contribuable2",
          };
        });
        result = false;
      }
      if (
        !state[
          "Sélections des deductions Pensions alimentaires Contribuable2"
        ]?.["Domicile"]
      ) {
        setStep4ValidationErrors((errors) => {
          return {
            ...errors,
            "Sélections des deductions Pensions alimentaires Contribuable2":
              "Sélections des deductions Pensions alimentaires Contribuable2",
          };
        });
        result = false;
      }
      if (
        !state[
          "Sélections des deductions Pensions alimentaires Contribuable2"
        ]?.["Adresse"]
      ) {
        setStep4ValidationErrors((errors) => {
          return {
            ...errors,
            "Sélections des deductions Pensions alimentaires Contribuable2":
              "Sélections des deductions Pensions alimentaires Contribuable2",
          };
        });
        result = false;
      }
      if (
        !state[
          "Sélections des deductions Pensions alimentaires Contribuable2"
        ]?.["lien de parentes"]
      ) {
        setStep4ValidationErrors((errors) => {
          return {
            ...errors,
            "Sélections des deductions Pensions alimentaires Contribuable2":
              "Sélections des deductions Pensions alimentaires Contribuable2",
          };
        });
        result = false;
      }
    }
    return result;
  };

  const validateFiles = (form) => {
    let result = true;
    fileList.forEach((file) => {
      if (!form?.files?.[file.fileName]) {
        setFilesValidationErrors((errors) => {
          return {
            ...errors,
            [file.fileName]: file.fileName,
          };
        });
        result = false;
      }
    });
    return result;
  };

  const checkPrice = (state) => {
    if (state?.price && state.price > 250) {
      navigate("/refusal");
      return true;
    }
  };

  const checkInvalidFields = (form) => {
    if (form["Plusieurs employeurs"] === "plus") {
      navigate("/refusal");
      return true;
    }

    if (form["Plusieurs employeurs Contribuable2"] === "plus") {
      navigate("/refusal");
      return true;
    }

    if (form["Sélections des revenus"]?.["Activité indépendante"]) {
      navigate("/refusal");
      return true;
    }

    if (
      form["Sélections des revenus Contribuable2"]?.["Activité indépendante"]
    ) {
      navigate("/refusal");
      return true;
    }

    if (form["Sélections des fortune"]?.["Immeubles"]) {
      navigate("/refusal");
      return true;
    }

    if (form["Sélections des fortune Contribuable2"]?.["Immeubles"]) {
      navigate("/refusal");
      return true;
    }

    if (form["combien comptes bancaires"]?.number === "Plus de 4") {
      navigate("/refusal");
      return true;
    }

    if (
      form["combien comptes bancaires Contribuable2"]?.number === "Plus de 4"
    ) {
      navigate("/refusal");
      return true;
    }

    if (form["combien assurance 3b"]?.number === "Plus de 4") {
      navigate("/refusal");
      return true;
    }

    if (form["combien assurance 3b Contribuable2"]?.number === "Plus de 4") {
      navigate("/refusal");
      return true;
    }

    if (form["Sélections des fortune"]?.["Actions"]) {
      navigate("/refusal");
      return true;
    }

    if (form["Sélections des fortune Contribuable2"]?.["Actions"]) {
      navigate("/refusal");
      return true;
    }

    if (form["Sélections des fortune"]?.["Fonds de placement/obligations"]) {
      navigate("/refusal");
      return true;
    }

    if (
      form["Sélections des fortune Contribuable2"]?.[
        "Fonds de placement/obligations"
      ]
    ) {
      navigate("/refusal");
      return true;
    }

    if (form["combien OPP3"]?.number === "Plus de 4") {
      navigate("/refusal");
      return true;
    }

    if (form["combien OPP3 Contribuable2"]?.number === "Plus de 4") {
      navigate("/refusal");
      return true;
    }

    if (form["Combien Frais médicaux et dentaires"]?.number === "Plus de 4") {
      navigate("/refusal");
      return true;
    }

    if (
      form["Combien Frais médicaux et dentaires Contribuable2"]?.number ===
      "Plus de 4"
    ) {
      navigate("/refusal");
      return true;
    }

    if (form["Combien Dons"]?.number === "Plus de 4") {
      navigate("/refusal");
      return true;
    }

    if (form["Combien Dons Contribuable2"]?.number === "Plus de 4") {
      navigate("/refusal");
      return true;
    }

    if (
      form["Combien Assurances maladie et accidents"]?.number === "Plus de 4"
    ) {
      navigate("/refusal");
      return true;
    }

    if (
      form["Combien Assurances maladie et accidents Contribuable2"]?.number ===
      "Plus de 4"
    ) {
      navigate("/refusal");
      return true;
    }
  };

  const validatePersonalInformation = (form) => {
    let result = true;
    const requiredFields = ["name and lastname", "email", "mobile"];
    if (!form) {
      result = false;
      setPersonalInformationValidationErrors({
        "name and lastname": "name and lastname",
        email: "email",
        mobile: "mobile",
      });
    } else {
      requiredFields.forEach((key) => {
        if (!form[key]) {
          setPersonalInformationValidationErrors((errors) => {
            return { ...errors, [key]: key };
          });
          result = false;
        }
      });
    }

    return result;
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const validateEmail = (form) => {
    let result = true;
    if (form["email"] && !isValidEmail(form["email"])) {
      setEmailError("Email is invalid");
      result = false;
    }

    return result;
  };

  const invalidateEmail = () => {
    setEmailError(null);
  };

  return (
    <div className="form-container">
      <div className="loadingbar">
        <div className="step-indicator">{state.step}/6</div>
        <div
          className="loadingbar-fill"
          style={{ width: `${state.step * 16.66}%` }}
        ></div>
      </div>
      {state.step === 1 && (
        <>
          <div className="refund-desc">
            <h4 className="form-section-title">{t("taxrefundstep1.enter")}</h4>
            {i18n.language === "en" ? (
              <p className="form-description">
                {t("taxrefundstep1.text.define")}
                <span className="hightlight-text">
                  &nbsp;{t("taxrefundstep1.text.tpprogile")}&nbsp;
                </span>
                {t("taxrefundstep1.text.by")}
                <span className="hightlight-text">
                  &nbsp; {t("taxrefundstep1.text.quest")}&nbsp;
                </span>
                {t("and")}
                <span className="hightlight-text">
                  &nbsp; {t("taxrefundstep1.text.submit")}&nbsp;
                </span>
                {t("taxrefundstep1.text.efficient")}{" "}
                <span className="hightlight-text">
                  {" "}
                  {t("taxrefundstep1.text.now")}
                </span>
                !
              </p>
            ) : (
              <p className="form-description">
                Définissons votre{" "}
                <span className="hightlight-text">profil contribuable</span>,
                répondez à notre
                <span className="hightlight-text"> questionnaire</span> et
                déposez vos pièces en ligne. Efficace, sans stress et{" "}
                <span className="hightlight-text">adapté pour vous</span>.
                Faites en l'expérience dès
                <span className="hightlight-text"> maintenant</span> !
              </p>
            )}
          </div>
          <div style={{ marginTop: "50px" }}>
            <div style={{ position: "relative" }}>
              <CustomSelect
                onChange={onChange}
                value={
                  i18n.language === "en"
                    ? state?.["Situation personnelle"]?.value
                    : state?.["Situation personnelle"]?.frenchValue
                }
                showOptions={showOptions}
                setShowOptions={setShowOptions}
                label={t("taxrefundstep1.fields.perssituetion")}
                selectedValueWidth="100%"
                options={marriageStatusOptions}
                handleResetValue={handleResetValue}
              />
              <ValidationError
                error={step1ValidationErrors["Situation personnelle"]}
                topPosition={"-20px"}
              />
            </div>

            <div style={{ marginTop: "50px", position: "relative" }}>
              <CustomSelect
                showOptions={showProfessionalStatusOptions}
                label={t("taxrefundstep1.fields.profsituation")}
                selectedValueWidth="100%"
                options={professionalSituationOptions}
                setShowOptions={setShowProfessionalStatusOptions}
                onChange={handleChangeProfessionalSituation}
                value={
                  i18n.language === "en"
                    ? state?.["Situation professionnelle"]?.value
                    : state?.["Situation professionnelle"]?.frenchValue
                }
                handleResetValue={handleResetProfessionalSituation}
                partOfList={false}
              />
              <ValidationError
                error={step1ValidationErrors["Situation professionnelle"]}
                topPosition={"-20px"}
              />
            </div>
            {state?.["Situation personnelle"]?.frenchValue === "Marié" && (
              <div style={{ marginTop: "50px", position: "relative" }}>
                <CustomSelect
                  showOptions={showSpouseProfessionalStatusOptions}
                  label={t("taxrefundstep1.fields.spouseprofsituation")}
                  selectedValueWidth="100%"
                  options={professionalSituationOptions}
                  setShowOptions={setShowSpouseProfessionalStatusOptions}
                  onChange={handleChangeSpouseProfessionalSituation}
                  value={
                    i18n.language === "en"
                      ? state?.["Spouse Situation professionnelle"]?.value
                      : state?.["Spouse Situation professionnelle"]?.frenchValue
                  }
                  handleResetValue={handleResetSpouseProfessionalSituation}
                  partOfList={false}
                />
                <ValidationError
                  error={
                    step1ValidationErrors["Spouse Situation professionnelle"]
                  }
                  topPosition={"-20px"}
                />
              </div>
            )}
            <DependentChildren
              validationErrors={step1ValidationErrors}
              invalidateStep1Errors={invalidateStep1Errors}
            />
          </div>
        </>
      )}
      {state.step === 2 && (
        <>
          <Step2
            step2ValidationErrors={step2ValidationErrors}
            invalidateStep2Errors={invalidateStep2Errors}
          />
        </>
      )}
      {state.step === 3 && (
        <>
          <Step3
            step3ValidationErrors={step3ValidationErrors}
            invalidateStep3Errors={invalidateStep3Errors}
          />
        </>
      )}
      {state.step === 4 && (
        <>
          <Step4
            emailError={emailError}
            step4ValidationErrors={step4ValidationErrors}
            invalidateStep4Errors={invalidateStep4Errors}
            personalInformationValidationErrors={
              personalInformationValidationErrors
            }
            invalidatePersonalInformation={invalidatePersonalInformation}
            invalidateEmail={invalidateEmail}
          />
        </>
      )}
      {state.step === 5 && (
        <>
          <FileSubmitter
            fileList={fileList}
            setFileList={setFileList}
            filesValidationErrors={filesValidationErrors}
            invalidateFilesErrors={invalidateFilesErrors}
          />
        </>
      )}
      {state.step === 6 && (
        <>
          <Payment />
        </>
      )}

      <div className="next-step-button-container">
        {state.step > 1 && (
          <button onClick={handlePreviousStep} className="back-button">
            {t("back")}
          </button>
        )}

        {state.step !== 6 && (
          <button className="next-step-button" onClick={handleNextStep}>
            {state.step === 4 ? t("ok") : t("nextstep")}
          </button>
        )}
      </div>
      {state.step === 4 && i18n.language === "en" && (
        <div className="terms-policy-link">
          By clicking OK, you understand and accept the&nbsp;
          <span
            className="terms-policy-link-highlight"
            onClick={() => navigate("/termsofservice")}
          >
            Terms of Service
          </span>
          &nbsp; and&nbsp;
          <span
            className="terms-policy-link-highlight"
            onClick={() => navigate("/privacypolicy")}
          >
            Privacy Policy
          </span>
          .
        </div>
      )}
      {state.step === 4 && i18n.language === "fr" && (
        <div className="terms-policy-link">
          En cliquant sur D'accord, vous comprenez et acceptez les&nbsp;
          <span
            className="terms-policy-link-highlight"
            onClick={() => navigate("/termsofservice")}
          >
            Conditions générales
          </span>
          &nbsp;d'utilisation et la&nbsp;
          <span
            className="terms-policy-link-highlight"
            onClick={() => navigate("/privacypolicy")}
          >
            Politique de confidentialité
          </span>
          .
        </div>
      )}
    </div>
  );
}

export default TaxRefund;
