import React from "react";
import "./navbar.css";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const NavBar = ({ navigationOpen, setNavigationOpen }) => {
  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation();

  const handlePageRoute = (path) => {
    navigate(path);
    setNavigationOpen(false);
  };

  return (
    <>
      <nav
        className={navigationOpen ? "navigation navigation-open" : "navigation"}
      >
        <div className="navigation-logo">
          <img src="/vegtaxFooterLogo.png" alt="company logo" />
          <div className="header-poweredby">
            <p>{t("powered")}</p>
            <img src="/veg-icon.png" />
          </div>
        </div>
        <div>
          <ul className="navigation-list">
            <li onClick={() => handlePageRoute("/tax-refund")}>
              {t("menu.taxrefund")}
            </li>
            <li onClick={() => handlePageRoute("/howitworks")}>
              {t("menu.howitworks")}
            </li>
            <li onClick={() => handlePageRoute("/ourconcept")}>
              {t("menu.ourconcept")}
            </li>
            <li onClick={() => handlePageRoute("/whoweare")}>
              {t("menu.aboutus")}
            </li>
            <li onClick={() => handlePageRoute("/contact")}>
              {t("menu.contact")}
            </li>
          </ul>
        </div>
      </nav>
      <nav className="desktop-navigation">
        <div>
          <ul className="navigation-list">
            <li onClick={() => handlePageRoute("/tax-refund")}>
              <span
                className="nav-li-text"
                style={{
                  color:
                    location.pathname === "/tax-refund" ? "#55c9ea" : "#000E2B",
                }}
              >
                {t("menu.taxrefund")}
              </span>
            </li>
            <li onClick={() => handlePageRoute("/howitworks")}>
              <span
                className="nav-li-text"
                style={{
                  color:
                    location.pathname === "/howitworks" ? "#55c9ea" : "#000E2B",
                }}
              >
                {t("menu.howitworks")}
              </span>
            </li>
            <li onClick={() => handlePageRoute("/ourconcept")}>
              <span
                className="nav-li-text"
                style={{
                  color:
                    location.pathname === "/ourconcept" ? "#55c9ea" : "#000E2B",
                }}
              >
                {t("menu.ourconcept")}
              </span>
            </li>
            <li onClick={() => handlePageRoute("/whoweare")}>
              <span
                className="nav-li-text"
                style={{
                  color:
                    location.pathname === "/whoweare" ? "#55c9ea" : "#000E2B",
                }}
              >
                {t("menu.aboutus")}
              </span>
            </li>
            <li onClick={() => handlePageRoute("/contact")}>
              <span
                className="nav-li-text"
                style={{
                  color:
                    location.pathname === "/contact" ? "#55c9ea" : "#000E2B",
                }}
              >
                {t("menu.contact")}
              </span>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
