import React, { useRef, useEffect, useState } from "react";
import "./payment.css";
import Receipt from "../receipt";
import { useAppState } from "../../contexts/formContext";
import StripeContainer from "../stripeContainer";
import StripeWrapper from "../stripeContainer";
import "@adyen/adyen-web/dist/adyen.css";
import AdyenCheckout from "@adyen/adyen-web";
import axios from "axios";
import ValidationError from "../validationError";
import { useTranslation } from "react-i18next";

function Payment(props) {
  const twintContainerRef = useRef();
  const [state, setState] = useAppState();
  const [activePaymentMethod, setActivePaymentMethod] = useState();
  const [serverError, setServerError] = useState(false);
  const [success, setSuccess] = useState(false);

  const [sendingRequest, setSendingRequest] = useState(false);
  const [t, i18n] = useTranslation("global");

  const selectActivePaymentMethod = (method) => {
    if (method === activePaymentMethod) {
      return;
    } else {
      setActivePaymentMethod(method);
    }
  };

  const testSession = () => {
    setSendingRequest(true);
    axios
      .post(
        "http://localhost:5000/api/save-form",
        {
          form: state,
        },
        { withCredentials: true }
      )
      .then((res) => {
        setServerError(false);
        setSendingRequest(false);
        setSuccess(true);
        console.log(res);
      })
      .catch((error) => {
        setSuccess(false);
        console.log(error);
        setServerError(error);
        setSendingRequest(false);
      });
  };

  const checkSession = () => {
    axios
      .post(
        "http://localhost:5000/api/send-form/",
        { test: "test" },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        console.log(res);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // testSession();
    return () => {};
  }, []);

  const paymentMethods = [
    {
      id: "1",
      value: "Card",
      frenchValue: "Carte",
    },
    {
      id: "2",
      value: "PayPal",
      frenchValue: "PayPal",
    },
    {
      id: "3",
      value: "Twint",
      frenchValue: "Twint",
    },
  ];

  useEffect(() => {
    const createSession = async () => {
      const res = await axios.post("http://localhost:5000/api/session");
      const configuration = {
        environment: "test", // Change to 'live' for the live environment.
        clientKey: "test_X4YYKZGRUJHDDGWMJLATJFZGHAFNAGHQ", // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication

        session: {
          id: res.data.id,
          sessionData: res.data.sessionData,
        },
        onPaymentCompleted: (result, component) => {
          console.info(result, component);
        },
        onError: (error, component) => {
          console.error(error.name, error.message, error.stack, component);
        },
        // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
        // For example, this is 3D Secure configuration for cards:
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true,
            holderNameRequired: true,
            billingAddressRequired: true,
          },
        },
      };
      const checkOut = await AdyenCheckout(configuration);

      if (twintContainerRef.current) {
        checkOut.create("twint").mount(twintContainerRef.current);
      }
    };
    // const createCheckout = async () => {
    //   const checkOut = await AdyenCheckout({});
    //   if (twintContainerRef.current) {
    //     checkOut.create("twint").mount(twintContainerRef.current);
    //   }
    // };
    // createCheckout();
    // if (activePaymentMethod === "Twint") {
    //   createSession();
    // }
  }, [activePaymentMethod]);

  return (
    <div style={{ position: "relative" }}>
      <h4 className="payment-component-title">{t("payment.title")}</h4>
      {/* <button onClick={checkSession}>Send form</button> */}
      <ValidationError
        error={serverError}
        message={serverError?.message}
        topPosition={"40px"}
      />
      <Receipt step={state?.step} price={state?.price} />

      <ul className="payment-buttons-wrapper">
        {paymentMethods.map((method) => {
          return (
            <li key={method.id}>
              <button
                id={`payment-button-${method.id}`}
                className={"payment-button"}
                onClick={() => selectActivePaymentMethod(method.value)}
                style={{
                  backgroundColor:
                    method.value === activePaymentMethod
                      ? "#E8DEF8"
                      : "transparent",
                }}
              >
                {i18n.language === "en" ? method.value : method.frenchValue}
                {method.value === activePaymentMethod && (
                  <span className="payment-button-check">
                    <img src="checkBird.png" />
                  </span>
                )}
              </button>
            </li>
          );
        })}
      </ul>
      {activePaymentMethod === "Card" && !serverError && success && (
        <StripeWrapper price={state?.price} />
      )}

      {activePaymentMethod === "Twint" && !serverError && success && (
        <div
          id="twint-container"
          style={{ marginTop: "30px" }}
          ref={twintContainerRef}
        ></div>
      )}

      {sendingRequest && <div className="page-overlay"></div>}
    </div>
  );
}

export default Payment;
