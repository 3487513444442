import React from "react";
import "./footer.css";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

function Footer(props) {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation("global");

  const handlePageRoute = (path) => {
    navigate(path);
  };
  return (
    <div className="footer">
      <div className="footer-upper-section">
        <div className="footer-logo-nav">
          <div className="footer-logo">
            <img
              src="/vegtaxFooterLogo.png"
              alt="company logo"
              className="footer-logo-img"
              onClick={() => handlePageRoute("/")}
            />
            <div className="poweredby">
              <p>{t("powered")}</p>
              <img src="/veg-icon.png" alt="powered by" />
            </div>
          </div>
          <div className="footer-navigation">
            <ul className="footer-navigation-list">
              <li onClick={() => handlePageRoute("/tax-refund")}>
                <span className="nav-li-text">{t("menu.taxrefund")}</span>
              </li>
              <li onClick={() => handlePageRoute("/howitworks")}>
                <span className="nav-li-text">{t("menu.howitworks")}</span>
              </li>
              <li onClick={() => handlePageRoute("/ourconcept")}>
                <span className="nav-li-text">{t("menu.ourconcept")}</span>
              </li>
              <li onClick={() => handlePageRoute("/whoweare")}>
                <span className="nav-li-text">{t("menu.aboutus")}</span>
              </li>
              <li onClick={() => handlePageRoute("/contact")}>
                <span className="nav-li-text">{t("menu.contact")}</span>
              </li>
              <li onClick={() => handlePageRoute("/privacypolicy")}>
                <span className="nav-li-text">{t("menu.priv")}</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-social-block">
          <div className="social-icon">
            <img src="/linkedin.png" />
          </div>
          <div className="social-icon">
            <img src="/facebook.png" />
          </div>
          <div className="social-icon">
            <img src="/youtube.png" />
          </div>
        </div>
      </div>
      <div className="footer-bottom-section">
        <p>
          © 2024 <span style={{ fontWeight: "bold" }}>VEG</span>
          <span className="hightlight-text-blue" style={{ fontSize: "12px" }}>
            TAX
          </span>
          . {t("allrights")}
        </p>
      </div>
    </div>
  );
}

export default Footer;
