import React, { useEffect } from "react";

import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

import "./privacy.css";

function Privacy(props) {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page-margin">
      <h4 className="page-title">{t("privacy")}</h4>
      <h5 className="priv-title">{t("prstatement")}</h5>
      {i18n.language === "en" && (
        <div className="eligibility-descriptions ">
          <div className="privacy-block">
            <h4>1. GENERAL</h4>
            <p className="privacy-description">
              Our company VEG Consulting Sàrl , Avenue du Théâtre 7, 1005
              Lausanne (hereinafter: “our Company”), undertakes to respect the
              privacy of its clients and to comply with all applicable data
              protection laws. and confidentiality.
            </p>
            <p className="privacy-description">
              <p className="privacy-description">
                Our company VEG Consulting Sàrl , Avenue du Théâtre 7, 1005
                Lausanne (hereinafter: “our Company”), undertakes to respect the
                privacy of its clients and to comply with all applicable data
                protection laws. and confidentiality.
              </p>
              <p className="privacy-description">
                In order to offer its services, our Company may be required to
                transfer personal data to third parties, in particular to the
                authorities. These transfers of information take place only in
                the execution of the mandate entrusted to us or in accordance
                with legal provisions and in compliance with applicable data
                protection laws. By contracting with our Company, our clients
                accept that their data may be transmitted to third parties as
                part of the execution of the mandate or service contract
                entrusted to us. These data protection provisions define the
                personal data we collect, process and use about our customers.
                We therefore thank you for reading the following carefully.
              </p>
            </p>
          </div>
          <div className="privacy-block">
            <h4>2. WHO PROCESSES OUR CUSTOMERS’ INFORMATION?</h4>
            <p className="privacy-description">
              Our Company is responsible for processing the personal data of its
              customers, users of its website and any person whose data it
              processes within the meaning of the LPD.
            </p>

            <p className="privacy-description">
              The personal data of our clients and the persons concerned are
              processed only by the employees and consultants of our Company in
              the context of the execution of the mandate entrusted to it or the
              transmission of information such as our newsletters or invitations
              to events concerning our Company. The digital data is saved on a
              secure and regularly updated server.
            </p>
          </div>
          <div className="privacy-block">
            <h4>3. WHAT ARE THE SOURCES OF THE PERSONAL DATA WE PROCESS?</h4>
            <p className="privacy-description">
              When we provide services to our customers, in addition to the
              personal data we process about our customers, we may also process
              personal data of third parties or data that we have not collected
              directly from the data subject (indirect processing). data from
              the provision of services. These third parties are generally
              employees, contact persons, family members or people who are in
              contact with our customers or data subjects for other reasons. We
              need this personal data in order to execute our contracts with our
              customers. These personal data are transmitted to us by our
              customers or by third parties such as insurance companies, banks,
              social institutions that they have mandated. It is our customers'
              responsibility to inform third parties whose data is processed.
              For this purpose, our customers may refer to this data protection
              declaration.
            </p>
          </div>
          <div className="privacy-block">
            <h4>4. WHAT INFORMATION IS PROCESSED?</h4>
            <p className="privacy-description">
              We only collect and process personal data necessary for the
              performance of the contract with our customers or our suppliers of
              goods and services. The data that we collect from our customers or
              that we receive concerning third parties includes, in particular,
              their last name, first name, postal address, e-mail address,
              telephone number, bank account, AVS number and VAT number for
              registered traders, they may also include sensitive personal data
              such as health data.
            </p>
            <p className="privacy-description">
              Personal data also includes information relating to the use of our
              website (e.g. IP address, MAC address of the smartphone or
              computer, settings data, cookies, date, place and time of the
              visit on the site, pages and site contents consulted, functions
              used as well as other connection data and sources that you
              consult).
            </p>

            <p className="privacy-description">
              In addition to data transmitted directly by our customers, the
              categories of personal data that we receive from third parties
              include, in particular, data from public registers, data of which
              we become aware in the context of administrative or judicial
              procedures, data linked with the functions and activities of our
              customers including financial activities, data concerning our
              customers contained in correspondence and interviews with third
              parties, information on solvency and assets, data relating to
              compliance with legal requirements such as combating against money
              laundering and export restrictions, data from banks, insurance
              companies, distribution partners and other contractual partners of
              our organization for the use or provision of services to our
              customers and data from the media and the Internet.
            </p>
            <p className="privacy-description">
              In the event that our Company is required to process sensitive
              personal data, within the meaning of the LPD, it undertakes to
              process this data with the greatest care and increased diligence
              on the basis of the execution of the contract with us. entrusts.
            </p>
            <p className="privacy-description">
              Sensitive personal data, within the meaning of article 5 letter a
              LPD, means:
            </p>
            <p className="privacy-description">
              <p>
                – data on religious, philosophical, political or trade union
                opinions or activities,
              </p>
              <p>
                – data on health, intimate sphere or racial or ethnic origin,
              </p>
              <p> – genetic data,</p>
              <p>
                – biometric data identifying a natural person in a manner
                unambiguous,
              </p>
              <p>
                – data on criminal and administrative proceedings or sanctions,
              </p>
              <p> – data on social assistance measures.</p>
            </p>
          </div>
          <div className="privacy-block">
            <h4>5. UNDER WHAT PRINCIPLES ARE YOUR DATA PROCESSED?</h4>
            <p className="privacy-description">
              When processing the data of our customers, or of any data subject,
              we take into account the processing principles of legality,
              proportionality, finality, transparency – in particular compliance
              with information obligations – and data security. We take
              appropriate measures to ensure that personal data is accurate.
            </p>
            <p className="privacy-description">
              Data protection requirements are taken into account when planning
              and designing processing activities (data protection by design and
              by default) .
            </p>
            <p className="privacy-description">
              We do not make any automated individual decisions.
            </p>
          </div>
          <div className="privacy-block">
            <h4>
              6. USE OF OUR WEBSITE AND INFORMATION ANALYSIS AND TRACKING
              TECHNOLOGY (COOKIES)
            </h4>
            <p className="privacy-description">
              To use our website, it is not necessary to disclose personal data
              until our customers subscribe to our services and transfer
              personal data to us which will be processed in accordance with
              these provisions. However, each time you consult the site, the
              server collects, among other things, the following information
              concerning your computer, tablet, Smartphone: the IP address of
              your computer, tablet, Smartphone, the request from your browser,
              the place and time of this request. The status and amount of data
              transmitted are recorded as part of this request. We also collect
              information about the product and version of the browser used and
              the operating system of your computer. This information is
              temporarily stored in server log files. The IP address of your
              computer is only recorded for the use of the website and is then
              quickly deleted or anonymized by shortening.
            </p>
            <p className="privacy-description">
              The collection of this information or data is technically
              necessary to display our website and ensure its stability and
              security. This information is also collected to improve the
              operation of our website, in particular to detect and correct
              errors, to determine the usage rate and to make adaptations or
              improvements to our website. For this purpose we collect, in
              particular, technical data which is transmitted automatically
              (e.g. IP address, UDI, device type, browser, number of clicks on
              the page, opening of the newsletter, click on links, etc.).
            </p>
            <p className="privacy-description">
              We use cookies on our website. These are small files that your
              browser automatically creates and which are saved on your device
              (laptop, tablet, smartphone, etc.) when you visit our website.
            </p>
            <p className="privacy-description">
              Cookies help in many ways to make your visit to our website
              simpler, more pleasant and more meaningful. Cookies are
              information files that your web browser automatically stores on
              your computer's hard drive when you visit our website.
            </p>
            <p className="privacy-description">
              For example, we use cookies to temporarily save the services you
              have chosen and the data you have entered when you fill out a form
              on the website, so that you do not have to repeat the entry when
              you visit another subpage. Cookies are also used, where
              applicable, to be able to identify you as a registered user after
              registering on the website, without you having to log in again
              when viewing another subpage.
            </p>
            <p className="privacy-description">
              If you do not wish to use browser cookies, you can configure your
              browser so that it does not accept the storage of cookies. Please
              note that in this case you will only be able to use our website to
              a limited extent, if at all. If you only want to accept our own
              cookies and not those of our service providers and partners, you
              can select the “Block third-party cookies” setting in your
              browser.
            </p>
          </div>
          <div className="privacy-block">
            <h4>7. INTENDED USE</h4>
            <p className="privacy-description">
              We use your personal data for the following purposes:
            </p>
            <p className="privacy-description">
              <p>7.1 To provide the services you have requested</p>
              <p>
                7.2 To fulfill our obligations arising from any contract entered
                into between you and us
              </p>
              <p>
                7.3 To enable you to participate in interactive offers, if you
                wish to do so
              </p>
              <p>
                7.4 To inform you of changes and new features to our services
              </p>
              <p>
                7.5 To communicate to you any useful information related to our
                activities
              </p>
              <p>
                7.6 To ensure that our website is presented to you in the most
                effective and interesting way possible
              </p>
            </p>
          </div>
          <div className="privacy-block">
            <h4>8. USE OF THE NEWSLETTER</h4>
            <p className="privacy-description">
              If our customers subscribe to our newsletter, we use their email
              address and other contact data to transmit the newsletter. Our
              customers can unsubscribe from the newsletter at any time.
            </p>
          </div>
          <div className="privacy-block">
            <h4>9. WHO IS OUR CUSTOMER DATA SHARED WITH?</h4>
            <p className="privacy-description">
              Our Company will not use or communicate the personal data of its
              customers or data subjects to third parties, unless it has a legal
              basis and/or administrative or judicial orders (this may be in
              particular the communication of information for the purposes of
              criminal prosecution, prevention of threats or enforcement of
              intellectual property rights) and/or express authorization in the
              context of the execution of the contract with our clients or our
              providers of goods and services. No personal data will therefore
              be shared with third parties, unless this is necessary by law or
              in the context of the execution of the mandate.
            </p>
            <p className="privacy-description">
              We conclude contracts with service providers (e.g. for our IT
              system) who process personal data on our behalf (processors). By
              signing these contracts, they undertake to guarantee data
              protection. We are responsible for the careful selection of
              service providers and remain accountable to our customers and the
              Federal Data Protection Commissioner for the transparency of
              outsourced personal data processing in accordance with the DPA.
            </p>
          </div>
          <div className="privacy-block">
            <h4>10. HOW ARE OUR CUSTOMERS’ PERSONAL DATA STORED?</h4>
            <p className="privacy-description">
              Our Company has implemented technical and organizational
              protection measures to protect customer data against unauthorized
              access, collection, use, disclosure, copying, modification, sale
              or any other similar risk.
            </p>
            <p className="privacy-description">
              All digital personal data of our customers is stored
              electronically in Switzerland, on a database protected by a
              firewall. Our services are hosted in a secure server environment,
              which uses a firewall and other advanced technologies to prevent
              interference or outside access. Unfortunately, the transmission of
              information over the Internet is not completely secure, therefore
              we cannot guarantee the security of data transmitted to our site
              via the Internet.
            </p>
            <p className="privacy-description">
              All physical personal data of our customers is stored and
              protected against external access.
            </p>
          </div>
          <div className="privacy-block">
            <h4>11. HOW LONG IS OUR CUSTOMER DATA KEPT?</h4>
            <p className="privacy-description">
              We process and retain our customers' data for as long as necessary
              to fulfill our contractual and legal obligations or to achieve the
              objectives pursued by the data processing. In most cases this
              shelf life is 5 years.
            </p>
          </div>
          <div className="privacy-block">
            <h4>12. WHAT ARE OUR CUSTOMERS’ RIGHTS REGARDING THEIR DATA?</h4>
            <p className="privacy-description">
              Our customers, or any persons concerned, can contact our Company's
              data protection manager at the following address:
              info@vegconsulting.ch (specifying “Data Protection” in the
              caption).
            </p>
            <p className="privacy-description">
              Our customers have the right to ask whether and which personal
              data concerning them are processed, access to their personal data,
              how our Company uses it and to whom it is transmitted, subject to
              the exceptions provided for by the DPA.
            </p>
            <p className="privacy-description">
              Any request for access to data must be made in writing to the
              email address above and provide sufficient detail to identify the
              personal data sought.
            </p>
            <p className="privacy-description">
              Our customers also have the right to ensure that their data is
              accurate. Any request for correction of data must be made in
              writing and provide sufficient detail to identify the data for
              which correction is requested.
            </p>
            <p className="privacy-description">
              Our customers can also request, under certain conditions, that our
              Company hand over their personal data to them or transmit it to
              another data controller. Finally, our customers have the right to
              request the prohibition of processing or communication or the
              erasure or destruction of data concerning them, subject to legal
              retention periods. Any request to prohibit the processing or
              erasure of data must be made in writing and provide sufficient
              detail to identify the data whose erasure is sought.
            </p>
          </div>
          <div className="privacy-block">
            <h4>13. AMENDMENT OF THE DATA PROTECTION STATEMENT</h4>
            <p className="privacy-description">
              We expressly reserve the right to change this data protection
              declaration at any time with effect for the future.
            </p>
            <p className="privacy-description">
              The updated version is available on the website. We invite you to
              regularly consult the website and inform yourself about the
              confidentiality and data protection policy in force.
            </p>
            <p className="privacy-description">Lausanne, February 2024</p>
          </div>
        </div>
      )}
      {i18n.language === "fr" && (
        <div className="eligibility-descriptions ">
          <div className="privacy-block">
            <h4>1. GÉNÉRALITÉS</h4>
            <p className="privacy-description">
              Notre société VEG Consulting Sàrl, Avenue du Théâtre 7 à 1005
              Lausanne (ci-après: « notre Société »), s’engage à respecter la
              vie privée de ses clients et à se conformer à toutes les lois
              applicables en matière de protection des données et de
              confidentialité.
            </p>

            <p className="privacy-description">
              Dans ce but, notre Société veille à ce que les données de ses
              clients soient confidentielles, sécurisées et exactes. Chaque
              client peut, ainsi, accéder aux données personnelles qui le
              concernent, demander des corrections ou un effacement dans le
              respect des prescriptions légales applicables, en particulier de
              la loi suisse sur la protection des données (ci-après: « la LPD
              »).
            </p>
            <p className="privacy-description">
              Afin d’offrir ses services, notre Société peut être amenée à
              transférer des données personnelles à des tiers, notamment aux
              autorités. Ces transferts d’informations interviennent uniquement
              dans l’exécution du mandat qui nous est confié ou selon des
              provisions légales et dans le respect des lois applicables en
              matière de protection des données. En contractant avec notre
              Société, nos clients acceptent que leurs données puissent être
              transmises à des tiers dans le cadre de l’exécution du contrat de
              mandat ou de prestations qui nous est confié. Les présentes
              dispositions relatives à la protection des données définissent les
              données personnelles que nous collectons, traitons et utilisons au
              sujet de nos clients. Nous vous remercions donc de lire
              attentivement ce qui suit.
            </p>
          </div>
          <div className="privacy-block">
            <h4>2. QUI EST-CE QUI TRAITE LES INFORMATIONS DE NOS CLIENTS ?</h4>
            <p className="privacy-description">
              Notre Société est le responsable du traitement des données
              personnelles de ses clients, des utilisateurs de son site Internet
              et de toute personne dont elle traite des données au sens de la
              LPD.
            </p>

            <p className="privacy-description">
              Les données personnelles de nos clients et des personnes
              concernées sont traitées uniquement par les collaborateurs et
              consultants de notre Société dans le cadre de l’exécution du
              mandat qui lui a été confié ou de la transmission d’informations
              telles que nos newsletters ou invitations à des évènements
              concernant notre Société. Les données numériques sont sauvegardées
              sur un serveur sécurisé et régulièrement mis à jour.
            </p>
          </div>
          <div className="privacy-block">
            <h4>
              3. QUELLES SONT LES SOURCES DES DONNÉES PERSONNELLES QUE NOUS
              TRAITONS ?
            </h4>
            <p className="privacy-description">
              Lorsque nous fournissons des prestations à nos clients, en plus
              des données personnelles que nous traitons de nos client il se
              peut que nous traitions également des données personnelles de
              tiers ou des données que nous n’avons pas collectées directement
              auprès des personnes concernées (traitement indirect des données
              provenant de la fourniture de prestations). Ces tiers sont en
              général des collaborateurs, des personnes de contact, des membres
              de la famille ou des personnes qui sont en relation avec nos
              clients ou les personnes concernées pour d’autres raisons. Nous
              avons besoin de ces données personnelles, afin d’exécuter nos
              contrats conclus avec nos clients. Ces données personnelles nous
              sont transmises par nos clients ou par des tiers tels que des
              assurances, banques, institutions sociales qu’ils ont mandatés. Il
              incombe à nos clients d’informer les tiers dont les données sont
              traitées. A cette fin, nos clients peuvent faire référence à la
              présente déclaration de protection des données.
            </p>
          </div>
          <div className="privacy-block">
            <h4>4. QUELLES SONT LES INFORMATIONS TRAITÉES ?</h4>
            <p className="privacy-description">
              Nous ne collectons et ne traitons que les données personnelles
              nécessaires à l’exécution du contrat avec nos clients ou nos
              prestataires de biens et services. Les données que nous récoltons
              de nos clients ou que nous recevons concernant des tiers incluent
              notamment, leur nom, prénom, adresse postale, adresse e-mail,
              numéro de téléphone, compte bancaire, numéro AVS et numéro TVA
              pour les commerçants enregistrés, elles peuvent aussi comprendre
              des données personnelles sensibles telles que des données
              concernant la santé.
            </p>
            <p className="privacy-description">
              Les données personnelles comprennent également des informations en
              lien avec l’utilisation de notre site Internet (p.ex. adresse IP,
              adresse MAC du Smartphone ou de l’ordinateur, données de
              paramétrage, cookies, date, lieu et heure de la visite sur le
              site, pages et contenus du site consultés, fonctions utilisées
              ainsi que d’autres données de connexion et sources que vous
              consultez).
            </p>

            <p className="privacy-description">
              En plus des données transmises directement par nos clients, les
              catégories de données personnelles que nous recevons de tiers
              comprennent, notamment, des données issues de registres publics,
              des données dont nous avons connaissance dans le cadre de
              procédures administratives ou judiciaires, des données en lien
              avec les fonctions et activités de nos clients dont des activités
              financières, des données concernant nos clients contenues dans la
              correspondance et les entretiens avec des tiers, des informations
              sur la solvabilité et la fortune, des données portant sur le
              respect des prescriptions légales comme la lutte contre le
              blanchiment d’argent et les restrictions d’exportation, des
              données de banques, d’assurances, de partenaires de distribution
              et d’autres partenaires contractuels de notre organisation en vue
              de l’utilisation ou de la fourniture de prestations à nos clients
              et des données issues des médias et d’Internet.
            </p>
            <p className="privacy-description">
              Pour le cas où notre Société serait amenée à traiter des données
              personnelles sensibles, au sens de la LPD, elle s’engage à traiter
              ces données avec le plus grand soin et une diligence accrue sous
              la base de l’exécution du contrat qui nous est confié.
            </p>
            <p className="privacy-description">
              Par données personnelles sensibles, au sens de l’article 5 lettre
              a LPD, il faut comprendre :
            </p>
            <p className="privacy-description">
              <p>
                – les données sur les opinions ou les activités religieuses,
                philosophiques, politiques ou syndicales,
              </p>
              <p>
                – les données sur la santé, la sphère intime ou l’origine
                raciale ou ethnique,
              </p>
              <p>– les données génétiques,</p>
              <p>
                – les données biométriques identifiant une personne physique de
                manière univoque,
              </p>
              <p>
                – les données sur des poursuites ou sanctions pénales et
                administratives,
              </p>
              <p>– les données sur des mesures d’aide sociale.</p>
            </p>
          </div>
          <div className="privacy-block">
            <h4>5. SOUS QUELS PRINCIPES VOS DONNÉES SONT-ELLES TRAITÉES ?</h4>
            <p className="privacy-description">
              Lors du traitement des données de nos clients, ou de toutes
              personnes concernées, nous tenons compte des principes de
              traitement de la légalité, de la proportionnalité, de la finalité,
              de la transparence – en particulier le respect des obligations
              d’information – et de la sécurité des données. Nous prenons des
              mesures appropriées pour garantir que les données personnelles
              sont exactes.
            </p>
            <p className="privacy-description">
              Les exigences en matière de protection des données sont prises en
              compte dès la planification et la conception des activités de
              traitement (data protection by design and by default).
            </p>
            <p className="privacy-description">
              Nous ne prenons aucune décision individuelle automatisée.
            </p>
          </div>
          <div className="privacy-block">
            <h4>
              6. UTILISATION DE NOTRE SITE INTERNET ET DE TECHNOLOGIE D’ANALYSE
              ET DE SUIVI D’INFORMATION (COOKIES)
            </h4>
            <p className="privacy-description">
              Pour utiliser notre site Internet, il n’est pas nécessaire de
              divulguer des données personnelles jusqu’au moment où nos clients
              souscrivent à nos prestations et nous transfèrent des données
              personnelles qui seront traitées selon les présentes dispositions.
              Cependant, à chaque consultation du site, le serveur recueille
              entre autres les informations suivantes concernant votre
              ordinateur, tablette, Smartphone : l’adresse IP de votre
              ordinateur, tablette, Smartphone, la requête de votre navigateur,
              le lieu et l’heure de cette requête. Le statut et la quantité de
              données transmises sont enregistrés dans le cadre de cette
              demande.
            </p>
            <p className="privacy-description">
              Nous collectons également des informations sur le produit et la
              version du navigateur utilisé et du système d’exploitation de
              votre ordinateur. Ces informations sont temporairement stockées
              dans les fichiers journaux du serveur. L’adresse IP de votre
              ordinateur n’est enregistrée que pour l’utilisation du site
              Internet et est ensuite rapidement effacée ou rendue anonyme par
              raccourcissement.
            </p>
            <p className="privacy-description">
              La collecte de ces informations ou de données est techniquement
              nécessaire pour afficher notre site Internet et en garantir la
              stabilité et la sécurité. Ces informations sont, en outre,
              collectées pour améliorer le fonctionnement de notre site
              Internet, notamment pour détecter et corriger les erreurs, pour
              déterminer le taux d’utilisation et pour procéder à des
              adaptations ou des améliorations de notre site Internet. Dans ce
              but nous collectons, en particulier, des données techniques qui
              sont transmises automatiquement (p. ex. adresse IP, UDI, type
              d’appareil, navigateur, nombre de clics sur la page, ouverture de
              la newsletter, clic sur les liens, etc.).
            </p>
            <p className="privacy-description">
              Nous utilisons des cookies sur notre site Internet. Il s’agit de
              petits fichiers que votre navigateur crée automatiquement et qui
              sont enregistrés sur votre terminal (ordinateur portable,
              tablette, Smartphone, etc.), lorsque vous consultez notre site
              Internet.
            </p>
            <p className="privacy-description">
              Les cookies contribuent sous de nombreux aspects à rendre votre
              visite sur notre site Internet plus simple, plus agréable et plus
              judicieuse. Les cookies sont des fichiers d’information que votre
              navigateur Web enregistre automatiquement sur le disque dur de
              votre ordinateur lorsque vous visitez notre site Internet.
            </p>
            <p className="privacy-description">
              Nous utilisons par exemple des cookies pour enregistrer
              temporairement les prestations que vous avez choisies et les
              données que vous avez saisies lorsque vous remplissez un
              formulaire sur le site Internet, afin que vous ne deviez pas
              répéter la saisie lorsque vous consultez une autre sous page. Les
              cookies sont également utilisés, le cas échéant, pour pouvoir vous
              identifier en tant qu’utilisateur enregistré après votre
              inscription sur le site Internet, sans que vous ayez à vous
              connecter à nouveau lorsque vous consultez une autre sous-page
            </p>
            <p className="privacy-description">
              Si vous ne souhaitez pas utiliser de cookies de navigation, vous
              pouvez configurer votre navigateur de manière à ce qu’il n’accepte
              pas l’enregistrement de cookies. Veuillez noter que dans ce cas,
              vous ne pourrez utiliser notre site Internet que de manière
              limitée, voire pas du tout. Si vous ne souhaitez accepter que nos
              propres cookies et non ceux de nos prestataires de services et
              partenaires, vous pouvez sélectionner le paramètre « Bloquer les
              cookies tiers » dans votre navigateur
            </p>
          </div>
          <div className="privacy-block">
            <h4>7. UTILISATION PRÉVUE</h4>
            <p className="privacy-description">
              Nous utilisons vos données personnelles aux fins suivantes :
            </p>
            <p className="privacy-description">
              <p>
                7.1 Pour fournir les services et prestations que vous avez
                demandés
              </p>
              <p>
                7.2 Pour remplir nos obligations découlant de tout contrat
                conclu entre vous et nous
              </p>
              <p>
                7.3 Pour vous permettre de participer à des offres interactives,
                si vous le souhaitez
              </p>
              <p>
                7.4 Pour vous informer des modifications et nouveautés de nos
                prestations
              </p>
              <p>
                7.5 Pour vous communiquer toute information utile liée à nos
                activités
              </p>
              <p>
                7.6 Pour s’assurer que notre site Internet vous est présenté de
                la manière la plus efficace et la plus intéressante possible
              </p>
            </p>
          </div>
          <div className="privacy-block">
            <h4>8. UTILISATION DE LA NEWSLETTER</h4>
            <p className="privacy-description">
              Dans la mesure où nos clients s’abonnent à notre newsletter, nous
              utilisons leur adresse e-mail et d’autres données de contact pour
              transmettre la newsletter. Nos clients peuvent se désabonner à
              tout moment de la newsletter.
            </p>
          </div>
          <div className="privacy-block">
            <h4>
              9. AVEC QUI LES DONNÉES DE NOS CLIENTS SONT-ELLES PARTAGÉES ?
            </h4>
            <p className="privacy-description">
              Notre Société n’utilisera ou ne communiquera pas les données
              personnelles de ses clients ou de personnes concernées à des
              tiers, à moins qu’elle ne dispose d’une base légale et/ou
              d’ordonnances administratives ou judiciaires (il peut s’agir
              notamment de la communication d’informations à des fins de
              poursuites pénales, de prévention de menaces ou d’application des
              droits de propriété intellectuelle) et/ou d’une autorisation
              expresse dans le cadre de l’exécution du contrat avec nos clients
              ou nos prestataires de biens et services. Aucune donnée
              personnelle ne sera, ainsi, partagée avec des tiers, sauf si cela
              est nécessaire de par la loi ou dans le cadre de l’exécution du
              mandat.
            </p>
            <p className="privacy-description">
              Nous concluons des contrats avec des prestataires de services (p.
              ex. pour notre système informatique) qui traitent des données
              personnelles en notre nom (sous-traitants). En signant ces
              contrats, ils s’engagent à garantir la protection des données.
              Nous sommes responsables de la sélection rigoureuse des
              prestataires de services et nous restons responsables vis-à-vis de
              nos clients et du préposé fédéral à la protection des données et à
              la transparence du traitement des données personnelles externalisé
              conformément à la LPD.
            </p>
          </div>
          <div className="privacy-block">
            <h4>
              10. COMMENT LES DONNÉES PERSONNELLES DE NOS CLIENTS SONT-ELLES
              CONSERVÉES ?
            </h4>
            <p className="privacy-description">
              Notre Société a mis en place des mesures de protection techniques
              et organisationnelles pour protéger les données de ses clients
              contre l’accès, la collecte, l’utilisation, la divulgation, la
              copie, la modification, la vente ou tout autre risque similaire
              non autorisé.
            </p>
            <p className="privacy-description">
              Toutes les données personnelles numériques de nos clients sont
              stockées électroniquement en Suisse, sur une base de données
              protégée par un pare-feu. Nos services sont hébergés dans un
              environnement de serveur sécurisé, qui utilise un pare-feu et
              d’autres technologies avancées pour empêcher les interférences ou
              les accès extérieurs. Malheureusement, la transmission
              d’informations par Internet n’est pas entièrement sécurisée, c’est
              pourquoi nous ne pouvons pas garantir la sécurité des données
              transmises à notre site via Internet.
            </p>
            <p className="privacy-description">
              Toutes les données personnelles physiques de nos clients sont
              stockées et protégées contre tout accès extérieur.
            </p>
          </div>
          <div className="privacy-block">
            <h4>
              11. COMBIEN DE TEMPS LES DONNÉES DE NOS CLIENTS SONT-ELLES
              CONSERVÉES ?
            </h4>
            <p className="privacy-description">
              Nous traitons et conservons les données de nos clients aussi
              longtemps que cela est nécessaire pour remplir nos obligations
              contractuelles et légales ou pour atteindre les objectifs
              poursuivis par le traitement des données. Dans la plupart des cas
              cette durée de conservations est de 5 ans.
            </p>
          </div>
          <div className="privacy-block">
            <h4>
              12. QUELS SONT LES DROITS DE NOS CLIENTS CONCERNANT LEURS DONNÉES
              ?
            </h4>
            <p className="privacy-description">
              Nos clients, ou toutes personnes concernées, peuvent contacter le
              responsable en protection des données de notre Société à l’adresse
              suivante : info@vegconsulting.ch (en précisant dans le libellé «
              Protection des données »).
            </p>
            <p className="privacy-description">
              Nos clients ont le droit de demander si des données personnelles
              les concernant sont traitées et lesquelles, l’accès à leurs
              données personnelles, comment notre Société les utilise et à qui
              elles sont transmises, sous réserve des exceptions prévues par la
              LPD.
            </p>
            <p className="privacy-description">
              Toute demande d’accès aux données doit être faite par écrit à
              l’adresse mail ci-dessus et fournir suffisamment de détails pour
              identifier les données personnelles recherchées.
            </p>
            <p className="privacy-description">
              Nos clients ont également le droit de s’assurer que leurs données
              sont exactes. Toute demande de correction de données doit être
              faite par écrit et fournir suffisamment de détails pour identifier
              les données dont la correction est demandée.
            </p>
            <p className="privacy-description">
              Nos clients peuvent aussi demander, sous certaines conditions, que
              notre Société leur remette leurs données personnelles ou qu’elle
              les transmette à un autre responsable du traitement. Nos clients
              ont, enfin, le droit de demander l’interdiction d’un traitement ou
              d’une communication ou l’effacement ou la destruction des données
              les concernant, sous réserve des délais de détention légaux. Toute
              demande d’interdiction de traitement ou d’effacement de données
              doit être faite par écrit et fournir suffisamment de détails pour
              identifier les données dont l’effacement est recherché.
            </p>
          </div>
          <div className="privacy-block">
            <h4>
              13. MODIFICATION DE LA DÉCLARATION DE PROTECTION DES DONNÉES
            </h4>
            <p className="privacy-description">
              Nous nous réservons expressément le droit de modifier cette
              déclaration de protection des données à tout moment avec effet
              pour l’avenir.
            </p>
            <p className="privacy-description">
              La version actualisée est disponible sur le site Internet. Nous
              vous invitons à consulter régulièrement le site Internet et à vous
              informer sur la politique de confidentialité et de protection des
              données en vigueur.
            </p>
            <p className="privacy-description">Lausanne, février 2024 </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Privacy;
