import React, { useEffect, useState } from "react";
import "./filesubmitter.css";
import { useAppState } from "../../contexts/formContext";
import { v4 as uuidv4 } from "uuid";
import ValidationError from "../validationError";
import { useTranslation } from "react-i18next";

function FileSubmitter({
  fileList,
  setFileList,
  filesValidationErrors,
  invalidateFilesErrors,
}) {
  const [state, setState] = useAppState();
  const [fileSizeError, setFileSizeError] = useState({});
  const [t, i18n] = useTranslation("global");

  const fileSelectedHandler = (key, e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    if (file && file?.size > 1000000) {
      setFileSizeError((errors) => {
        return { ...errors, [key]: key };
      });
    } else {
      setFileSizeError((errors) => {
        const { [key]: er, ...rest } = errors;
        return { ...rest };
      });
      reader.onload = (event) => {
        if (event.target.result) {
          invalidateFilesErrors(key);
          setState((state) => {
            if (state?.files?.[key]) {
              return {
                ...state,
                files: {
                  ...state.files,
                  [key]: [
                    ...state.files[key],
                    {
                      id: uuidv4(),
                      base64: event.target.result,
                      fileName: file.name,
                      fileSize: file.size,
                      type: file.type,
                    },
                  ],
                },
              };
            } else {
              return {
                ...state,
                files: {
                  ...state.files,
                  [key]: [
                    {
                      id: uuidv4(),
                      base64: event.target.result,
                      fileName: file.name,
                      fileSize: file.size,
                    },
                  ],
                },
              };
            }
          });
        }
      };
    }

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const deleteFile = (key, id) => {
    setState((state) => {
      if (state?.files?.[key]?.length === 1) {
        const { [key]: fil, ...rest } = state.files;
        return {
          ...state,
          files: { ...rest },
        };
      } else {
        const newFiles = state.files[key].filter((file) => {
          return file.id !== id;
        });

        return {
          ...state,
          files: { ...state.files, [key]: newFiles },
        };
      }
    });
  };

  const generateFileList = (form) => {
    const files = [];

    files.push({
      fileName:
        "Formulaire original du Service cantonal des contributions – Feuille de transmission",
    });

    if (form["Sélections de déductions"]?.["Frais de garde"]) {
      files.push({ fileName: "Frais de garde des enfants" });
    }

    if (form["Sélections de déductions"]?.["Dons"]) {
      files.push({ fileName: "Quittances des dons" });
    }

    if (form["Sélections de déductions"]?.["Assurances maladie et accidents"]) {
      files.push({ fileName: "Primes d’assurance-maladie" });
    }

    if (form["Sélections de déductions"]?.["Frais médicaux et dentaires"]) {
      files.push({
        fileName:
          "Factures de dentiste, lunettes, frais médicaux payés personnellement",
      });
    }

    if (
      form["Allocations familiales type"] ===
        "Versees directement par la CAF" ||
      form["Allocations familiales type Contribuable2"] ===
        "Versees directement par la CAF"
    ) {
      files.push({ fileName: "Attestation d’allocations familiales" });
    }

    if (form["Sélections des revenus"]?.["Indémnités"]) {
      files.push({
        fileName:
          "Indemnités diverses, attestations du chômage, perte de gain maladie, accident, service militaire",
      });
    }

    if (
      form["Rentes et pensions type"]?.find((el) => el.value === "1er pillier")
    ) {
      files.push({
        fileName: "Attestation de la rente AVS/AI",
      });
    }

    if (
      form["Rentes et pensions type"]?.find(
        (el) => el.value === "3eme pillier"
      ) ||
      form["Rentes et pensions type"]?.find((el) => el.value === "2eme pillier")
    ) {
      files.push({
        fileName: "Attestation pour la rente 2ème pilier ou autres rentes",
      });
    }

    if (form["Rentes et pensions type"]?.type === "3eme pillier") {
      files.push({
        fileName: "Attestation(s) de versement(s) 3ème pilier 2023",
      });
    }

    if (form["Sélections des fortune"]?.["Comptes Bancaires"]) {
      files.push({
        fileName: "Attestations bancaires au 31.12.2023",
      });
    }

    if (form["Sélections des fortune"]?.["Assurance 3B"]) {
      files.push({
        fileName:
          "Attestation(s) assurance-vie pilier 3 B (attestation de la valeur de rachat au 31.12.2023)",
      });
    }

    if (form["Sélections des revenus"]?.["Activites salariale"]) {
      files.push({
        fileName: "Tous les certificats/attestations de salaires 2023",
      });
    }

    if (form["Sélections de déductions"]?.["Cotisatoin OPP3 (3ème pilier A)"]) {
      files.push({
        fileName: "Attestation(s) de versement(s) 3ème pilier 2023",
      });
    }

    if (form["Sélections de déductions"]?.["Rachats dannées dassurances"]) {
      files.push({
        fileName: "Attestation(s) rachat LPP en 2023",
      });
    }

    if (form["Sélections de déductions"]?.["Déductions logement"]) {
      files.push({
        fileName: "Bail à loyer",
      });
    }
    setFileList(files);
    return files;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const fList = generateFileList(state);

    if (state?.files) {
      let newFiles = {};
      Object.keys(state.files).map((key) => {
        const match = fList.find((element) => element.fileName === key);
        if (match) {
          newFiles = { ...newFiles, [key]: state.files[key] };
        }
      });

      setState((state) => {
        return { ...state, files: newFiles };
      });
    }
  }, []);

  return (
    <div>
      <div className="refund-desc">
        <h4> {t("filesubmit.title")}</h4>
      </div>

      {fileList?.length > 0 &&
        fileList.map((item) => {
          return (
            <div
              className="file-input-wrapper"
              key={item.fileName}
              style={{ position: "relative" }}
            >
              <ValidationError
                error={filesValidationErrors?.[item.fileName]}
                message="Required"
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                }}
              >
                <span className="file-input-label">{item.fileName}</span>

                <label htmlFor={item.fileName} className="file-input-button">
                  <img src="fileUploadIcon.png" alt="file upload" />
                </label>
              </div>

              {state?.files?.[item.fileName]?.length > 0 && (
                <ul>
                  {state?.files?.[item.fileName].map((file) => {
                    return (
                      <li className="filename" key={file.id}>
                        <span className="filename-text">{file.fileName}</span>

                        <button
                          className="delete-file-button"
                          onClick={() => deleteFile(item.fileName, file.id)}
                        >
                          <img src="deleteCitcleButton.png" />
                        </button>
                      </li>
                    );
                  })}
                </ul>
              )}
              {fileSizeError?.[item.fileName] && (
                <ValidationError
                  error={fileSizeError?.[item.fileName]}
                  message="Only files les than 1MB"
                  topPosition={
                    state?.files?.[item.fileName]?.length > 0 ? "100%" : "140%"
                  }
                />
              )}

              <input
                id={item.fileName}
                type="file"
                accept="image/png, image/jpeg, application/pdf"
                onChange={(e) => fileSelectedHandler(item.fileName, e)}
              />
            </div>
          );
        })}
    </div>
  );
}

export default FileSubmitter;
