import React from "react";
import "./alimonyinputs.css";
import { useTranslation } from "react-i18next";

function AlimonyInputs({ onChange, incomeAlimony }) {
  const [t, i18n] = useTranslation("global");
  return (
    <div className="alimony-inputs-wrapper">
      <h6 className="input-title">{t("trefundstep2.fields.resfrom")}</h6>
      <div className="alimony-input-wrapper ">
        <input
          className="activity-input"
          type="text"
          placeholder={t("trefundstep2.fields.name")}
          onChange={(e) => onChange(e, "Nom/Prenom")}
          value={incomeAlimony?.["Nom/Prenom"] || ""}
        />
      </div>
      <div className="alimony-input-wrapper">
        <input
          className="activity-input"
          type="text"
          placeholder={t("trefundstep2.fields.addr")}
          onChange={(e) => onChange(e, "Adresse")}
          value={incomeAlimony?.["Adresse"] || ""}
        />
      </div>
      <div className="alimony-input-wrapper ">
        <input
          className="activity-input"
          type="text"
          placeholder={t("trefundstep2.fields.res")}
          onChange={(e) => onChange(e, "Domicile")}
          value={incomeAlimony?.["Domicile"] || ""}
        />
      </div>
      <div className="alimony-input-wrapper">
        <input
          className="activity-input"
          type="text"
          placeholder={t("trefundstep2.fields.amount")}
          onChange={(e) => onChange(e, "Montant")}
          value={incomeAlimony?.["Montant"] || ""}
        />
      </div>
    </div>
  );
}

export default AlimonyInputs;
