import React from "react";
import "./validationerror.css";

function ValidationError({ error, topPosition, message = "Required field" }) {
  return (
    <div
      className="validation-error"
      style={{ top: topPosition ? topPosition : "100%" }}
    >
      {error && <p>{message}</p>}
    </div>
  );
}

export default ValidationError;
