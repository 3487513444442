import { useNavigate } from "react-router";
import "./homepage.css";
import { useTranslation } from "react-i18next";

const HomePage = () => {
  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate();

  return (
    <div className="homepage">
      <div className="homepage-hero">
        <div className="homepage-hero-image">
          <img src="/homepage-hero.png" alt="tax refund" />
        </div>
        <div className="homepage-hero-slogan-block">
          <h1 className="homepage-hero-slogan">
            <span className="homepage-hero-slogan-1">
              {t("homepage.welcome.title")}
              <span style={{ color: "#55C9EA" }}>TAX</span>.
            </span>
            <span className="homepage-hero-slogan-2">
              {t("homepage.welcome.subtitle")}
            </span>
          </h1>
          <p className="homepage-hero-slogan-text">
            {t("homepage.welcome.text.ourmission")}
            <span className="hightlight-text">
              {" "}
              {t("homepage.welcome.text.efficient")}
            </span>{" "}
            {t("homepage.welcome.text.and")}
            <span className="hightlight-text">
              {" "}
              {t("homepage.welcome.text.reliable")}
            </span>{" "}
            {t("homepage.welcome.text.solution")}{" "}
            <span className="hightlight-text">
              {t("homepage.welcome.text.taxdeclaration")}
            </span>
            , {t("homepage.welcome.text.making")}
            <span className="hightlight-text">
              {" "}
              {t("homepage.welcome.text.faster")}
            </span>{" "}
            {t("homepage.welcome.text.andplus")}
            <span className="hightlight-text">
              {" "}
              {t("homepage.welcome.text.easier")}
            </span>{" "}
            {t("homepage.welcome.text.than")}
          </p>
          <button
            className="get-tax-refund-btn"
            onClick={() => navigate("/tax-refund")}
          >
            {t("homepage.gettaxrefundtoday")}
          </button>
        </div>
      </div>
      <div className="how-it-works-block">
        <div className="cloud">
          <img src="/cloud.png" className="mobile-cloud" alt="blue cloud" />
          <img
            src="/cloudImageLarge.png"
            className="desk-cloud"
            alt="blue cloud"
          />
        </div>
        <h3 className="how-it-works-block-title">{t("menu.howitworks")}?</h3>
        <div className="how-it-works-subtitle-1">
          <img src="/eligibilityIcon.png" alt="eligibility" />
          <p>{t("homepage.assess")}</p>
        </div>
        <div className="how-it-works-subtitle-2">
          <img src="/uploadIcon.png" alt="eligibility" />
          <p>{t("homepage.submit")}</p>
        </div>
        <div className="how-it-works-subtitle-3">
          <img src="/dollarIcon.png" alt="eligibility" />
          <p>{t("homepage.pay")}</p>
        </div>
        <div className="btn-positioned">
          <button
            className="get-tax-refund-btn"
            style={{ width: "130px" }}
            onClick={() => navigate("/howitworks")}
          >
            {t("homepage.learn")}
          </button>
        </div>
      </div>
      <div className="customer-revie-block">
        <p>
          <span style={{ position: "relative" }}>
            S
            <span className="left-lapka">
              <img src="leftLapka.png" />
            </span>
          </span>
          {t("homepage.mobsimpl")}
        </p>
        <p>{t("homepage.mobvt")}</p>
        <p>{t("homepage.mobpr")}</p>
        <p>{t("homepage.mpbeff")}</p>
        <p>{t("homepage.mobmoney")}</p>
        <p>
          {t("homepage.mobrec")}
          <span style={{ position: "relative" }}>
            !
            <span className="right-lapka">
              <img src="rightLapka.png" />
            </span>
          </span>
        </p>
      </div>
      <div className="customer-revie-block-desktop">
        <p> {t("homepage.simplicity")}</p>
        <p style={{ position: "relative" }}>
          <span style={{ position: "relative" }}>
            V
            <span className="left-lapka">
              <img src="leftLapka.png" />
            </span>
          </span>
          egTax
          {t("homepage.made")}
        </p>

        <p>{t("homepage.reclaimed")}</p>
        <p>
          {t("homepage.recommended")}
          <span style={{ position: "relative" }}>
            !
            <span className="right-lapka">
              <img src="rightLapka.png" />
            </span>
          </span>
        </p>
      </div>
      <div className="who-we-are-block">
        <div className="homepage-hero">
          <div className="homepage-hero-image">
            <img src="/tax-refund.png" alt="tax refund" />
          </div>
          <div className="homepage-hero-slogan-block">
            <h1 className="homepage-hero-slogan">
              <span className="who-we-are-title">
                {t("homepage.whoweare.title")}
              </span>
            </h1>
            {i18n.language === "en" ? (
              <p className="homepage-hero-slogan-text">
                <span className="hightlight-text">VEG Consulting</span>
                {t("homepage.whoweare.recognized")}
                <span className="hightlight-text">
                  {t("homepage.whoweare.tax")}
                </span>{" "}
                {t("and")}
                <span className="hightlight-text">
                  {" "}
                  {t("homepage.whoweare.financial")}{" "}
                </span>
                {t("homepage.whoweare.consulting")}
                <span className="hightlight-text">VEG</span>
                <span className="hightlight-text-blue">TAX</span>.{" "}
                {t("homepage.whoweare.thisinnovative")}&nbsp;
                <span className="hightlight-text">
                  {t("homepage.whoweare.swissind")}{" "}
                </span>
                {t("homepage.whoweare.handle")}.
              </p>
            ) : (
              <p className="homepage-hero-slogan-text">
                <span className="hightlight-text">VEG Consulting</span>, un
                leader reconnu dans le domaine du conseil&nbsp;
                <span className="hightlight-text">fiscal</span> et{" "}
                <span className="hightlight-text">financier</span> dans le
                canton de Vaud, est fier d'annoncer le lancement de son tout{" "}
                <span className="hightlight-text">nouveau produit :</span>{" "}
                <span className="hightlight-text">VEG</span>{" "}
                <span className="hightlight-text-blue">TAX</span>. Ce site
                internet <span className="hightlight-text">innovant</span> est
                spécialement conçu pour{" "}
                <span className="hightlight-text">solutionner</span> la manière
                dont les particuliers suisses gèrent leurs{" "}
                <span className="hightlight-text">déclarations d'impôt</span> .
              </p>
            )}
            <button
              className="get-tax-refund-btn"
              onClick={() => navigate("/whoweare")}
            >
              {t("homepage.learnmore")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
