import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/homePage";
import Layout from "./components/Layout";
import TaxRefundPage from "./pages/taxRefundPage";
import PaymentPage from "./pages/paymentPage";
import SuccessPage from "./pages/successPage";
import FailurePage from "./pages/failurePage";
import HowItWorksPage from "./pages/howItWorksPage";
import OurConceptPage from "./pages/ourConceptPage";
import WhoWeArePage from "./pages/whoWeArePage";
import ContactPage from "./pages/contactPage";
import PrivacyPage from "./pages/privacyPage";
import TermsOfUsePage from "./pages/termsOfUsePage";

import React, { useEffect } from "react";
import axios from "axios";

function App() {
  useEffect(() => {
    axios
      .get("http://localhost:5000/api/check-form/", { withCredentials: true })
      .then((res) => {
        console.log(res);
      });
  }, []);
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="tax-refund" element={<TaxRefundPage />} />
          <Route path="refusal" element={<FailurePage />} />
          <Route path="payment" element={<PaymentPage />} />
          <Route path="success" element={<SuccessPage />} />
          <Route path="howitworks" element={<HowItWorksPage />} />
          <Route path="ourconcept" element={<OurConceptPage />} />
          <Route path="whoweare" element={<WhoWeArePage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="privacypolicy" element={<PrivacyPage />} />
          <Route path="termsofservice" element={<TermsOfUsePage />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
